@import 'contact-landing';
@import 'contact-aec';

/*------------------------------------*\
    PAGE - CONTACT
\*------------------------------------*/

.contact {
  // Body
  // =============================================================================

  .page-body {
    background: color(brand-white);
  }


  // Footer
  // =============================================================================

  .page-footer {
    background: color(brand-black-faded);
    border: 0 none;

    .vcard {
      @include margin(0 0 40px);
    }

    .fn {
      @include margin(40px 0 20px);
      font-size: rem(16px);
      font-weight: 700;
      line-height: 1.6;
    }

    .s_tel,
    .s_fax {
      strong {
        color: color(brand-blue);
      }
    }

    .s_tel {
      @include margin(5px 0 0);
    }

    .s_fax {
      @include margin(0 0 5px);
    }

    .email {
      font-weight: 700;
      color: #fff;

      a {
        color: inherit;
        text-decoration: none;
        transition: color 0.2s $ease-out-quart;

        &:hover,
        &:focus,
        &:active {
          color: color(brand-blue);
          text-decoration: none;
        }
      }
    }
  }


  // Member
  // =============================================================================

  .member {
    @include margin(0 0 30px);

    @include mq($until: tablet) {
      @include margin(0 0 15px);
    }
  }


  // Post
  // =============================================================================

  .post {
    &__header {
      min-height: 90px;
    }
  }


  // Posts
  // =============================================================================

  .post {
    @include margin(0 0 30px);

    @include mq($until: tablet) {
      @include margin(0 0 15px);
    }

    &:hover {
      background: color(brand-red);

      // Title
      .post__title {
        color: #fff;
      }

      // Subhead
      .post__subhead {
        color: rgba(#fff, 0.6);

        svg {
          fill: #fff;
        }
      }

      // Button
      .post__button {
        border-color: rgba(#fff, 0.4);

        svg {
          fill: #fff;
        }
      }
    }
  }
}
