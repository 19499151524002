/*------------------------------------*\
    PAGE - AGENDA
\*------------------------------------*/

.country-agenda {
  // Post
  // =============================================================================

  .post {
    &__figure {
      display: block;
    }
  }


  // Events
  // =============================================================================

  .content-events {
    .grid__title {
      border-bottom: 0 none;

      &::after {
        display: none;
      }
    }
  }
}
