/*------------------------------------*\
    CONTENT - BASE
\*------------------------------------*/

.content {
  @include padding(55px 0);
  @include margin(0);
  background: #fff;

  // Member/Post
  .member,
  .post {
    background: color(brand-white);
  }

  & + .content {
    @include padding(0 0 55px);
  }

  & + .content-media {
    @include padding(0 0 140px);

    @include mq($until: desktop) {
      @include padding(30px 0);
    }

    @include mq($until: tablet) {
      @include padding(15px 0);
    }
  }

  & + .content--grey,
  & + .content--white,
  & + .content--black {
    @include padding(55px 0);
  }

  &--white {
    & + .content-map {
      border-color: white;
    }
  }

  &--grey {
    background: color(brand-white);

    // Member/Post
    .member,
    .post {
      background: #fff;
    }

    & + .content {
      @include padding(55px 0);
    }

    & + .content--grey,
    & + .content--white {
      @include padding(0 0 55px);
    }
  }

  &--black {
    background: color(brand-black-faded);

    // Member/Post
    .member,
    .post {
      background: #fff;
    }

    & + .content {
      @include padding(55px 0);
    }

    & + .content--black {
      @include padding(0 0 55px);
    }
  }

  &--reverse {
    .col {
      float: right;
    }
  }

  &-media {
    &:not(.no-image) + .content {
      @include padding(80px 0 55px);

      @include mq($until: tablet) {
        @include padding(55px 0);
      }
    }

    &:not(.no-image) + .content-media:not(.no-image) {
      @include padding(0 0 140px);

      @include mq($until: desktop) {
        @include padding(30px 0);
      }

      @include mq($until: tablet) {
        @include padding(15px 0);
      }
    }

    &:first-child {
      padding-top: 55px;
    }
  }

  &-data {
    & + .content {
      @include padding(55px 0);
    }
  }

  & + .content-case,
  & + .content-data {
    @include padding(0);
  }
}
