/*------------------------------------*\
    BOTTOM LINK
\*------------------------------------*/

.bottom-link {
  @include margin(0 0 -290px);

  @include mq($until: desktop) {
    @include margin(0 0 30px);
  }


  // Figure
  // =============================================================================

  .post__figure {
    @include size(100%, 280px);
  }


  // Content
  // =============================================================================

  .post__content {
    min-height: 0;
  }


  // Subhead
  // =============================================================================

  .post__subhead {
    @include padding(0);
  }
}
