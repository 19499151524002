/*------------------------------------*\
    FILTERS
\*------------------------------------*/

.filters {
  .form-field {
    @include margin(null null 15px);

    &:last-child {
      @include margin(null null 0);
    }
  }

  .fs-dropdown {
    @include margin(0);
  }
}
