/*------------------------------------*\
    POPUP
\*------------------------------------*/

.popup {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s $ease-out-quart;
  z-index: 1500;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }

  &.is-hidden {
    visibility: visible;
    opacity: 0;
  }


  // Cache
  // =============================================================================

  &__cache {
    @include position(fixed, 0 null null 0);
    @include size(100%);
    background: color(brand-black);
    cursor: pointer;
    opacity: 0.95;
    z-index: -1;
  }


  // Close
  // =============================================================================

  &__close {
    @include position(absolute, 50px 50px null null);
    @include padding(0);
    @include font(lato, black, family weight);
    cursor: pointer;
    font-size: rem(12px);
    line-height: 1;
    letter-spacing: ls(120px);
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;

    @include mq($until: desktop) {
      right: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }


  // Form
  // =============================================================================

  .search-form {
    @include position(absolute, 50% null null 50%);
    @include size(100%, auto);
    max-width: $gs-container;
    margin-left: auto;
    margin-right: auto;
    transform-origin: 50%;
    transform: translate(-50%, -50%);

    @include mq($until: desktop) {
      left: 30px;
      right: 30px;
      width: auto;
      transform: translate(0, -50%);
    }

    @include mq($until: tablet) {
      left: 15px;
      right: 15px;
    }
  }
}
