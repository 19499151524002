/*------------------------------------*\
    BUTTONS
\*------------------------------------*/

// Common
// =============================================================================

.button {
  display: inline-block;
  @include size(auto, 50px);
  @include padding(0 30px);
  background: color(brand-red);
  border-radius: 2px;
  border: 0 none;
  outline: none;
  cursor: pointer;
  transition: background 0.2s $ease-out-quart;
  @include font(lato, bold, family weight);
  font-size: rem(14px);
  line-height: 48px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: none;

  &--red {
    background: color(brand-red);
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #fff;
    background: tint(color(brand-red), 15%);
  }
}


// Buttons Expanded
// =============================================================================

.button--expand {
  display: block;
  width: 100%;
  @include padding(null 15px);
}


// Circular
// =============================================================================

.button--circular {
  display: inline-block;
  @include position(relative);
  @include size(54px);
  background: transparent;
  border: 2px solid rgba(#fff, 0.2);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  transition: border 0.2s $ease-out-quad;

  .svg {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
  }

  svg {
    transition: fill 0.4s $ease-out-quad;
    fill: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: rgba(#fff, 0.5);
  }
}


// Controls
// =============================================================================

.button--controls {
  border-color: rgba(color(brand-black), 0.2);

  svg {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    fill: color(brand-red);
  }

  &:hover,
  &:focus,
  &:active {
    border-color: rgba(color(brand-black), 0.5);
  }
}


// Buttons Disabled
// =============================================================================

.button[disabled],
.loading {
  background: color(brand-grey-lighten);
  //pointer-events: none;
  cursor: not-allowed;

  &:hover,
  &:active,
  &:focus {
    background: color(brand-grey-lighten);
  }
}
