/*------------------------------------*\
    PAGE - REGION
\*------------------------------------*/

.region {
  // Header
  // =============================================================================

  .page-header {
    &__title {
      font-size: rem(45px);
    }

    &__content {
      width: 50%;
      @include margin(0 0 50px);

      @include mq($until: desktop) {
        width: 100%;
      }
    }
  }


  // Footer
  // =============================================================================

  .page-footer {
    @include position(static);
    @include padding(20px 0 0);
    border: 0 none;
    background: color(brand-white);
  }
}

@import 'region-list';
@import 'region-detail';
