/*------------------------------------*\
    PAGE - MENU
\*------------------------------------*/

.page-search {
  // Container
  // =============================================================================

  &__container {
    @include position(relative);
    @include margin(-65px auto 0);
    max-width: $gs-container;
    background: #fff;
    z-index: 1;

    @include mq($until: 1120px) {
      @include margin(0 auto);
    }

    @include mq($until: tablet) {
      //@include padding(30px 15px);
    }
  }

  /// Form
  form {
    position: relative;
  }

  /// Form Control
  .autocomplete-control {
    display: block;
    width: 100%;
    @include padding(30px 120px 30px 30px);
    font-size: rem(16px);
    border: 0 none;
  }

  /// Form Toggle
  .autocomplete-toggle {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 30px;
    padding-right: 30px;
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(200px);
    text-transform: uppercase;
    color: color(brand-blue);
    transition: color 0.2s $ease-out-quart;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 1px;
      height: 20px;
      margin-top: -10px;
      background: color(border);
    }

    &:hover,
    &:focus,
    &:active {
      color: color(brand-red);
    }
  }

  /// Form Autocomplete
  .autocomplete-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    max-height: 250px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    border-top: 1px solid rgba(color(border), 0.5);
    z-index: 9999;
  }

  /// Form Option
  .autocomplete-section,
  .autocomplete-option {
    border-bottom: 1px solid rgba(color(border), 0.5);

    a {
      display: block;
      padding: 20px 30px;
      @include font(lato, bold, family weight);
      font-size: rem(16px);
      line-height: 1;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  .autocomplete-items {
    .autocomplete-option a {
      padding-left: 45px;
    }
  }

  .autocomplete-section {
    display: block;
    border: 0 none;

    .autocomplete-option {
      display: none;
    }

    > .autocomplete-option {
      background: #F8F8F8;
      border-bottom: 2px solid rgba(color(border), 0.5);
    }
  }
}
