/*------------------------------------*\
    HEADER - MENU TRIGGER
\*------------------------------------*/

.menu-trigger {
  @include position(absolute, 80px 30px null null);
  @include size(30px);
  @include margin(-15px 0 0);
  display: none;
  cursor: pointer;
  z-index: 999;

  @include mq($until: desktop) {
    display: block;
  }

  @include mq($until: tablet) {
    right: 15px;
  }

  .is-menu-open & {
    position: fixed;

    .line {
      background: #fff;

      &--top {
        transform: translate(0, 0) rotate(-45deg);
      }

      &--middle {
        opacity: 0;
      }

      &--bottom {
        transform: translate(0, 0) rotate(45deg);
      }
    }
  }


  // Lines
  // =============================================================================

  .line {
    display: block;
    @include position(absolute, 40% null null 0);
    @include size(100%, 4px);
    @include margin(-2px 0 0);
    background: color(brand-black);
    transform-origin: 50%;
    transition: background 0.2s $ease-out-quad, transform 0.2s $ease-out-quad, opacity 0.2s $ease-out-quad;

    &--top {
      transform: translate(0, -9px);
    }

    &--middle {
      transform: translate(0, 0);
    }

    &--bottom {
      transform: translate(0, 9px);
    }
  }
}
