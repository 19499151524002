/*------------------------------------*\
    CASE
\*------------------------------------*/

.case {
  // Content
  // =============================================================================

  &__content {
    @include size(50%, auto);
    @include padding(55px 80px 55px 0);
    @include margin(0);

    @include mq($until: desktop) {
      @include size(100%, auto);
      @include padding(30px 0);
    }
  }


  // Header
  // =============================================================================

  &__header {
    @include padding(0 0 40px);
    @include margin(0 0 40px);
    border-bottom: 1px solid color(border);

    @include mq($until: tablet) {
      @include padding(0 0 30px);
      @include margin(0 0 30px);
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include margin(0);
    @include font(lato, bold, family weight);
    font-size: rem(28px);
    line-height: 1.2;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    color: color(brand-red);

    @include mq($until: tablet) {
      font-size: rem(25px);
    }

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }


  // Subhead
  // =============================================================================

  &__subhead {
    @include margin(0 0 25px);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(150px);
    text-decoration: none;
    text-transform: uppercase;
    color: color(text);
    opacity: 0.75;
  }


  // Text
  // =============================================================================

  &__text {
    .lead {
      @include padding(0);
      @include font(merriweather, regular, family weight);
      font-size: rem(16px);
      line-height: 1.9;
      letter-spacing: 0;
      text-decoration: none;
      text-transform: none;

      &::before,
      &::after {
        display: none;
      }
    }

    .button {
      @include margin(20px 0 0);
      @include padding(null 30px);
    }
  }


  // Image
  // =============================================================================

  &__image {
    @include aspect-ratio(705px, 512px);
    @include position(absolute, 0 null 120px 50%);
    @include size(705px, auto);
    overflow: hidden;

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include size(100%, 450px);
    }

    @include mq($until: tablet) {
      @include size(100%, 290px);
    }

    .img {
      @include position(absolute, 0 null null 0);
      display: block;
      @include size(100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }


  // Author
  // =============================================================================

  &__author {
    @include position(absolute, null null 0 50%);
    @include size(320px, 120px);
    @include padding(34px 30px);
    background: color(brand-red);

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include size(100%, 120px);
      max-width: 50%;
    }

    @include mq($until: tablet) {
      max-width: 100%;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      @include position(absolute, 37px null null 30px);
      @include size(50px, 28px);
      border-bottom: 1px solid rgba(#fff, 0.2);
      background-image: url('../assets/images/quote-down.png');
      background-size: 20px 18px;
      background-position: left top;
      background-repeat: no-repeat;
    }

    &::after {
      @include position(absolute, 72px null null 30px);
      @include size(50px, 12px);
      background-image: url('../assets/images/quote-up.png');
      background-size: 14px 12px;
      background-position: right 5px top;
      background-repeat: no-repeat;
    }

    .vcard {
      @include position(absolute, 50% 30px null 100px);
      transform-origin: 50%;
      transform: translate(0, -50%);
    }

    .name {
      @include font(lato, bold, family weight);
      font-size: rem(16px);
      line-height: 1.9;
      letter-spacing: 0;
      text-decoration: none;
      text-transform: none;
      color: #fff;
    }

    .function {
      @include font(merriweather, italic, family weight style);
      font-size: rem(13px);
      line-height: 1.9;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      color: #fff;
    }
  }
}

