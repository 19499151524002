/*------------------------------------*\
    PAGE - LANDING
\*------------------------------------*/
.landing {
  @include size(100%);
  background-color: #fff;
  background-image: url('../assets/images/landing/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &__footer {
    background-color: color(brand-black);
    color:color(brand-white);
    height: rem(60px);
    width: 100%;
    display: block;
  }

  // Menu trigger
  // =============================================================================

  .menu-trigger {
    display: none;
  }

  // Center
  // =============================================================================

  .center {
    max-width: 1220px;

    @include mq($until: 1225px) {
      @include padding(0 30px);
    }

    @include mq($until: tablet) {
      @include padding(0 15px);
    }
  }

  // Columns
  // =============================================================================

  .col {
    @include clearfix();
    @include margin(0 20px 0 0);

    &:last-child {
      @include margin(0);
    }

    @include mq($until: 1225px) {
      @include margin(0);
    }
  }

  // Header
  // =============================================================================

  .site-header {
    @include margin(0 0 70px);

    @include mq($until: desktop) {
      @include margin(0 0 30px);
    }
  }

  // Hight Level
  // =============================================================================

  .hight-level {
    @include padding(0);
    background: transparent;
  }

  // Low Level
  // =============================================================================

  .low-level {
    z-index: auto;

    &::before {
      display: none;
    }
  }

  // Menu & Login
  // =============================================================================

  .menu,
  .login {
    display: none;
  }

  // Lang
  // =============================================================================

  .menu-lang {
    display: block;
    @include position(fixed, 60px 40px null null);
    @include margin(0);
    @include padding(0);
    z-index: 999;

    .menu__selected,
    .menu__list {
      background: rgba(#fff, 0.1);
    }

    .menu__selected {
      color: #fff;
    }

    @include mq($until: desktop) {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 50px;

      .menu__item {
        margin: 0 10px;
      }
    }
  }

  // Logo
  // =============================================================================

  .logo {
    float: none;
    @include margin(0 auto);

    a {
      background-image: url('../assets/images/logo-white.png');
    }
  }

  // Gateway
  // =============================================================================

  .gateway {
    width: 270px;

    &--dark {
      @include position(relative);
      @include margin(-70px 0 50px 185px);
      z-index: 1;
    }

    @include mq($until: 1225px) {
      float: left;
      max-width: 100%;
      width: calc(50% - 30px);
      margin-bottom: 30px;
      margin-right: 15px;
      margin-left: 15px;

      &--dark {
        @include margin(0 15px 30px);
      }
    }

    @include mq($until: tablet) {
      float: none;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 15px;

      &--dark {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }

}
