/*------------------------------------*\
    PAGE - COUNTRY CONTENT
\*------------------------------------*/

.country-content {
  // Footer
  // =============================================================================

  .page-footer {
    background: transparent;
    border: 0 none;
    padding: 30px 0 0;

    @include mq($until: desktop) {
      padding: 0;
    }
  }
}
