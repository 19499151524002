/*------------------------------------*\
    FOOTER - MENU TERTIARY
\*------------------------------------*/

.menu-tertiary {
  @include padding(0);
  @include margin(0);


  // List
  // =============================================================================

  .menu__list {
    @include margin(0);
  }


  // Items
  // =============================================================================

  .menu__item {
    display: block;
    @include margin(0);
    @include font(lato, regular, family weight);
    font-size: rem(13px);
    line-height: 2;
    letter-spacing: ls(10px);
    text-transform: none;
    //text-align: left;
    color: rgba(color(brand-black), 0.4);
  }


  // Links
  // =============================================================================

  .menu__link {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    transition: color 0.2s $ease-out-quart;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: color(brand-blue);
    }
  }
}
