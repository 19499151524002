/*------------------------------------*\
    PAGE - RESULTS
\*------------------------------------*/

.results {
  // Grid
  // =============================================================================
  .grid {
    &__title {
      @include filet(color(brand-blue));
      border-bottom: 1px solid color(border);
    }

    .post,
    .member {
      @include margin(0 0 30px);

      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }

    .post {
      &__figure {
        display: none;
      }
    }
  }
}
