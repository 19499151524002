/*------------------------------------*\
    CONTENT - DATA
\*------------------------------------*/

.content-data {
  @include position(relative);
  background: color(brand-black);
  z-index: 1;


  // Figure
  // =============================================================================

  &__figure {
    @include position(absolute, 0 null null 0);
    @include size(calc(50% - 225px), 100%);
    background-color: color(brand-blue-faded);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    @include mq($until: desktop) {
      display: none;
    }
  }
}
