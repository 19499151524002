$width: 50px;
$height: 50px;
$border-radius: 15px;
$right: 10px;
$bottom: 30px;

.back-to-top {
  position: fixed;
  z-index: 100;
  right: $right;
  opacity: 0;
  bottom: $bottom;
  background-color: color(brand-red);
  width: rem($width);
  height: rem($height);
  border-radius: rem($border-radius);
  padding: rem(10px);
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s 0s;
  @include mq($until: tablet) {
    &.is-visible {
      opacity: 0.7;
    }
  }
  &:hover, &:focus {
    transition: opacity 0.3s 0s;
    opacity: 1;
  }
  &:hover &__icon {
    transition: transform 0.2s $ease-in-quad;
    transform: translateY(rem(-3px));
  }
  &__icon {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    transition: transform 0.25s $ease-out-quad;
  }
}
