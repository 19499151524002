/*------------------------------------*\
    FOOTER - MENU FOOTER
\*------------------------------------*/

.menu-footer {
  @include padding(0);
  @include margin(0);


  // Title
  // =============================================================================

  .menu__title {
    @include margin(0 0 30px);
    @include font(lato, bold, family weight);
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: ls(10px);
    text-transform: none;
    text-decoration: none;
    color: #fff;
  }


  // List
  // =============================================================================

  .menu__list {
    @include margin(0);
  }


  // Items
  // =============================================================================

  .menu__item {
    display: block;
    @include margin(0);
    @include font(lato, regular, family weight);
    font-size: rem(14px);
    line-height: 1;
    letter-spacing: ls(10px);
    text-transform: none;
    text-decoration: none;
    color: rgba(#fff, 0.6);
  }


  // Links
  // =============================================================================

  .menu__link {
    display: inline-block;
    line-height: 30px;
    text-decoration: none;
    color: inherit;
    transition: color 0.2s $ease-out-quart;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: #fff;
    }
  }
}
