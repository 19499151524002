/*------------------------------------*\
    PAGE - MENU
\*------------------------------------*/

.page-menu {
  // Container
  // =============================================================================

  &__container {
    @include position(relative);
    @include padding(30px 30px 20px);
    @include margin(-65px auto 0);
    max-width: $gs-container;
    background: #fff;
    z-index: 1;

    &::after {
      content: '';
      @include position(absolute, 50% 50px null 50px);
      @include size(auto, 1px);
      background: color(brand-white);
      z-index: -1;
    }

    @include mq($until: 1120px) {
      @include margin(0 auto);
    }

    @include mq($until: tablet) {
      @include padding(30px 15px 20px);

      &::after {
        display: none;
      }
    }
  }


  // Wrapper
  // =============================================================================

  &__wrapper {
    @include padding(0 20px);
    @include clearfix();
    display: inline-block;
    background: #fff;

    @include mq($until: desktop) {
      @include padding(null null null 0);
    }

    @include mq($until: tablet) {
      display: block;
      @include padding(0);
    }
  }


  // Label
  // =============================================================================

  &__label {
    float: left;
    @include margin(14px 20px 0 0);
    @include font(lato, bold, family weight);
    font-size: rem(20px);
    line-height: 1;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    color: color(brand-black);

    @include mq($until: tablet) {
      float: none;
      display: block;
      margin-bottom: 15px;
      text-align: center;
    }
  }


  // Filters
  // =============================================================================

  .filters {
    float: left;

    @include mq($until: tablet) {
      float: none;
    }
  }


  // Formstone
  // =============================================================================

  .c-select {
    float: left;
    min-width: 240px;
    @include margin(0 20px 0 0);

    select {
      background: color(brand-white);
    }
  }

  .fs-dropdown {
    float: left;
    min-width: 240px;
    @include margin(0 20px 0 0);

    @include mq($until: tablet) {
      min-width: 0;
      float: none;
      @include margin(0);
    }

    &:last-child {
      margin-right: 0;
    }

    &-selected {
      background: color(brand-white);

      &::before {
        border-top-color: color(brand-white);
      }
    }

    &-options {
      background: color(brand-white);
    }
  }
}
