/*------------------------------------*\
    FORMS
\*------------------------------------*/

// Common
// =============================================================================

label {
  display: block;
  @include margin(0 0 10px);
  @include font(lato, black, family weight);
  font-size: rem(10px);
  line-height: 1.3;
  letter-spacing: ls(150);
  text-transform: uppercase;
  text-decoration: none;
  color: color(brand-black);
  cursor: pointer;

  .caption {
    display: block;
    @include margin(0 0 10px);
    @include font(merriweather, italic, family weight style);
    font-size: rem(12px);
    line-height: 1.7;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(text);

    &:first-child {
      @include margin(10px null null);
    }
    &:last-child {
      @include margin(0);
    }
  }
}

textarea {
  min-height: 200px;
  resize: vertical;
}

input,
select,
textarea {
  // Simplify elements
  border-radius: 0;
  border: 0 none;
  outline: none;
  background: transparent;
  // Remove default appearance
  -webkit-appearance: none;
}

button {
  background: transparent;
  border: 0 none;
  outline: none;
}


// Form Groups
// =============================================================================

.form-group {
  @include clearfix();
  @include margin(0 (-$gs-gutter / 2));

  .form-list,
  .form-field {
    float: left;
    width: 50%;
    @include padding(0 ($gs-gutter / 2));

    @include mq($until: tablet) {
      float: none;
      width: 100%;
    }
  }
}


// Form Lists
// =============================================================================

.form-list {
  @include margin(0 0 20px);

  &:last-child {
    @include margin(0);
  }

  @include mq($until: tablet) {
    @include margin(0 0 30px);
  }

  .c-checkbox,
  .c-radio {
    @include margin(0 0 15px);

    &:last-child {
      @include margin(0);
    }
  }
}


// Form Fields
// =============================================================================

.form-field {
  @include position(relative);
  @include margin(0 0 30px);

  &--dark {
    .form-control {
      border-color: transparent;
      color: #fff;

      &:focus {
        border-color: transparent;
      }
    }
  }

  &--error {
    .form-control {
      border-color: color(alert-error);
      background-color: tint(color(alert-error), 85%);
      color: color(alert-error)
    }
  }
}


// Form Steps
// =============================================================================

.form-step {
  @include size(100%, auto);
  @include margin(0 0 50px);
  background: #fff;

  @include mq($until: tablet) {
    @include margin(0 0 30px);
  }

  &__title {
    @include position(relative);
    @include margin(0);
    @include padding(35px);
    @include font(lato, bold, family weight);
    background: color(brand-grey-shaded);
    font-size: rem(25px);
    line-height: 1;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    color: color(heading);

    @include mq($until: tablet) {
      @include padding(35px 20px);
      font-size: rem(23px);
    }
  }

  &__number {
    float: left;
    display: inline-block;
    @include size(34px);
    @include margin(-5px 20px 0 0);
    border: 2px solid rgba(color(brand-black), 0.10);
    border-radius: 50%;
    font-size: rem(14px);
    line-height: 2.2;
    text-align: center;
    text-indent: -2px;

    @include mq($until: tablet) {
      display: none;
    }
  }

  .form-list {
    @include margin(30px 0);
    @include padding(0 40px);
    border-right: 1px dashed color(brand-grey-shaded);

    &:last-child {
      border: 0 none;
    }

    @include mq($until: tablet) {
      @include padding(20px);
      @include margin(0);
      border-right: 0 none;
      border-bottom: 1px dashed color(brand-grey-shaded);
    }
  }
}


// Form Controls
// =============================================================================

.form-control {
  @include size(100%, 50px);
  @include padding(10px 15px);
  background: rgba(#fff, 0.05);
  border: 2px solid color(brand-grey-lighten);
  border-radius: 2px;
  @include font(lato, bold, family weight);
  font-size: rem(14px);
  line-height: auto;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
  color: color(text);
  transition: border 0.2s $ease-out-quad;

  &:focus {
    border-color: tint(color(brand-grey), 25%);
  }
}

.form-control--outline {
  border-color: color(brand-grey-lighten);

  &:focus {
    border-color: tint(color(brand-grey), 25%);
  }
}


// Error
// =============================================================================

.error {
  display: block;
  @include margin(10px 0 0);
  @include font(merriweather, italic, family weight style);
  @include font-smoothing(false);
  font-size: rem(12px);
  line-height: 1.3;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  color: color(alert-error);

  &::before {
    content: '';
    display: inline-block;
    float: left;
    @include margin(6px 10px 0 0);
    @include size(10px, 1px);
    background: color(alert-error);
  }
}


// Prefix/Suffix
// =============================================================================

.prefix,
.suffix {
  @include size(50px, auto);
  @include font(lato, bold, family weight);
  line-height: 50px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
  text-align: center;
}

.prefix {
  @include position(absolute, null null 0 25px);

  &::after {
    content: '';
    display: inline-block;
    float: right;
    @include size(2px, 30px);
    @include margin(10px 0 0 10px);
    background: color(brand-grey-lighten);
  }

  & + input {
    @include padding(0 0 0 70px);
  }
}

.suffix {
  @include position(absolute, null 25px 0 null);

  &::before {
    content: '';
    display: inline-block;
    float: left;
    @include size(2px, 30px);
    @include margin(10px 10px 0 0);
    background: color(brand-grey-lighten);
  }

  & + input {
    @include padding(0 70px 0 0);
  }
}


// Custom Checkbox/Radio
// =============================================================================

.c-checkbox,
.c-radio {
  label {
    display: inline-block;
    @include position(relative);
    @include padding(0 0 0 35px);
    @include margin(0);
    font-size: rem(14px);
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    transition: opacity 0.2s $ease-out-quad;

    &::before {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(24px);
      border: 2px solid tint(color(brand-grey-lighten), 50%);
    }
  }

  input:checked {
    & + label::after {
      display: block;
    }
  }

  input[disabled] + label {
    opacity: 0.4;
    cursor: not-allowed;

    .c-tooltip:hover {
      cursor: not-allowed;

      .c-legend {
        display: none;
      }
    }
  }
}

.c-checkbox {
  label::after {
    content: '';
    display: none;
    @include position(absolute, 7px null null 7px);
    @include size(10px);
    background-image: url('../assets/images/checker.svg');
    background-position: left top;
    background-repeat: no-repeat;
  }
}

.c-radio {
  label::before {
    border-radius: 50%;
  }

  label::after {
    content: '';
    display: none;
    @include position(absolute, 7px null null 7px);
    @include size(10px);
    background: color(brand-red);
    border-radius: 50%;
  }
}


// Custom tooltip/legend
// =============================================================================

.c-tooltip {
  display: inline-block;
  @include position(relative);
  @include size(18px);
  @include margin(0 0 0 10px);
  background: color(brand-blue);
  border-radius: 50%;
  vertical-align: text-top;
  cursor: pointer;

  @include mq($until: desktop) {
    display: none;
  }

  .svg {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: #fff;
  }

  &:hover .c-legend {
    display: block;
  }
}

.c-legend {
  @include position(absolute, 100% null null 50%);
  @include padding(15px);
  @include size(250px, auto);
  @include margin(5px 0 0 -125px);
  background: #fff;
  border: 1px solid color(brand-white);
  box-shadow: 0 0 10px rgba(#000, 0.05);
  @include font(lato, regular, family weight);
  font-size: rem(14px);
  line-height: 1.7;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
  color: color(text);
  display: none;
  z-index: 999;
}


// Custom Select
// =============================================================================

.c-select {
  position: relative;

  select {
    @include size(100%, auto);
    @include padding(19px 20px);
    @include font(lato, bold);
    font-size: rem(13px);
    line-height: 1;
    letter-spacing: 0;
    color: color(brand-black);
    background-color: #fff;
    border-radius: 2px;
    cursor: pointer;
    transition: color 0.2s $ease-out-quart;

    &:hover {
      color: color(brand-red);
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    @include margin(auto 0);
    @include position(absolute, -4px 20px 0 null);
    @include size(0);
    border-top: 4px solid #fff;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    z-index: 1;
  }

  &::after {
    top: 0;
    right: 20px;
    border-top-color: color(brand-red);
    z-index: 0;
  }
}

.fs-dropdown {
  @include font(lato, bold, family weight);
  line-height: 1;
  letter-spacing: 0;
  color: color(brand-black);

  &-open {
    .fs-dropdown-selected {
      box-shadow: none;

      &::before {
        top: 1px;
        transform: rotate(180deg);
      }

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &-bottom {
    &.fs-dropdown-open {
      border-radius: 2px 2px 0 0;
    }

    .fs-dropdown-options {
      border-width: 2px 2px 0;
    }
  }
}

.fs-dropdown-element {
  .mobile & {
    z-index: 10;
  }
}

.fs-dropdown-selected {
  height: 50px;
  background-color: #fff;
  border: 0 none;
  border-radius: 2px;
  font-weight: 700;
  font-size: rem(13px);
  color: color(brand-black);
  outline: none;
  transition: color 0.2s $ease-out-quart;

  &::after {
    right: 20px;
    border-top-color: color(brand-red);
    z-index: 0;
  }

  &::before {
    content: '';
    display: block;
    @include margin(auto 0);
    @include position(absolute, -1px 21px 0 null);
    @include size(0);
    border-top: 4px solid #fff;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    z-index: 1;
  }

  &:hover {
    color: color(brand-red);
  }
}

.fs-dropdown-options {
  box-shadow: 0 2px 5px rgba(color(brand-black), 0.07);
  border: 0 none;
}

.fs-dropdown-item {
  height: 50px;
  border: 0 none;
  border-bottom: 1px solid tint(color(brand-black), 85%);
  background: none;
  font-size: rem(13px);
  color: color(text);
  transition: color 0.2s $ease-out-quart;

  &:hover,
  &:focus,
  &:active,
  &_selected {
    color: color(brand-red);
  }
}

.fs-dropdown-group {
  @include padding(15px null);
  background: tint(color(brand-black), 88%);
  border: 0 none;
}
