/*------------------------------------*\
    CONTENT - ASIDE
\*------------------------------------*/

.content-aside {
  @include mq($until: desktop) {
    @include margin(30px 0 0);
  }

  @include mq($until: tablet) {
    @include margin(20px 0 0);
  }

  // Picture
  // =============================================================================

  .picture {
    display: block;
    @include size(100%, auto);
    &.is-horizontal img {
      max-height: 20rem;
      object-fit: contain;
    }
    img {
      display: block;
      width: 100%;
      max-width: 100%;
      @include margin(0 auto);
    }

    @include mq($until: desktop) {
      img {
        max-width: 320px;
      }
    }
  }
}
