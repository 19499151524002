/*------------------------------------*\
    HEADER - MENU SECONDARY
\*------------------------------------*/

.menu-secondary {
  float: left;
  @include padding(0);
  @include margin(10px 0 0);

  @include mq($until: desktop) {
    float: none;
    margin-top: 0;
  }


  // List
  // =============================================================================

  .menu__list {
    @include margin(0 -10px);
    font-size: 0;
    text-align: right;

    @include mq($until: desktop) {
      text-align: center;
    }
  }


  // Items
  // =============================================================================

  .menu__item {
    display: inline-block;
    @include margin(0 10px);
    @include font(lato, bold, family weight);
    font-size: rem(9px);
    line-height: 40px;
    letter-spacing: ls(60px);
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: tint(color(brand-grey), 45%);

    @include mq($until: desktop) {
      display: block;
    }
  }


  // Links
  // =============================================================================

  .menu__link {
    font-weight: 900;
    display: inline-block;
    text-decoration: none;
    color: inherit;
    transition: color 0.2s $ease-out-quart;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      color: #fff;
      text-decoration: none;
    }

    @include mq($until: desktop) {
      &:hover,
      &:focus,
      &:active,
      &.is-active {
        color: inherit;
      }
    }
  }
}
