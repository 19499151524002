/*------------------------------------*\
    CONTACT BOX
\*------------------------------------*/

.contact-box {
  @include position(relative);
  @include size(calc(50% + (#{$gs-container} / 2)), auto);
  @include margin(0 0 -50px auto);
  background: #fff;
  font-size: 0;

  @include mq($until: desktop) {
    @include size(100%, auto);
    @include margin(0);
  }


  // Center
  // =============================================================================

  .center {
    margin-left: 0;
  }


  // Figure
  // =============================================================================

  &__figure {
    @include position(absolute, 0 null 0 0);
    @include size(320px, 100%);
    background-color: color(brand-grey);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: top;

    .shape {
      display: block;
      height: 100%;
    }

    @include mq($until: desktop) {
      display: none;
    }
  }


  // Content
  // =============================================================================

  &__content {
    display: inline-block;
    @include margin(0 0 0 320px);
    @include padding(50px);
    vertical-align: top;

    .button {
      @include margin(10px 0 0);
    }

    @include mq($until: desktop) {
      @include margin(0);
      @include padding(50px 0);
    }
  }


  // Subhead
  // =============================================================================

  &__subhead {
    @include margin(0 0 15px);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(150px);
    text-transform: uppercase;
    text-decoration: none;
    color: color(brand-black);
  }


  // Title
  // =============================================================================

  &__title {
    font-weight: 700;
    font-size: rem(28px);
    margin-bottom: 30px;

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }
}
