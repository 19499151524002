/*------------------------------------*\
    CONTENT - CASE
\*------------------------------------*/

.content-case {
  @include position(relative);
  @include padding(0);
  z-index: 1;

  &::before {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(50%, 100%);
    background: #fff;
    z-index: -1;

    @include mq($until: desktop) {
      display: none;
    }
  }


  // Slider
  // =============================================================================

  .case-slider {
    @include position(relative);

    .case {
      @include mq($from: desktop) {
        @include position(absolute, 0 null null 0);
        visibility: hidden;

        .case__header,
        .case__text {
          opacity: 0;
          transform: translate(0, 20px);
        }

        .case__image {
          span {
            opacity: 0;
          }
        }

        .case__author {
          visibility: hidden;

          .vcard {
            opacity: 0;
          }
        }

        &.is-visible {
          @include position(relative);
          visibility: visible;

          .case__header,
          .case__text {
            opacity: 1;
            transform: translate(0, 0);
            transition: transform 0.7s 0.7s $ease-out-quart, opacity 0.7s 0.7s $ease-out-quart;
          }

          .case__text {
            transition-delay: 0.8s;
            transition-timing: 0.6s;
          }

          .case__image {
            z-index: 1;

            span {
              opacity: 1;
              transition: opacity 0.9s 0.5s $ease-out-quart;
            }
          }

          .case__author {
            visibility: visible;
            transition: visibility 0s 0.7s;

            .vcard {
              opacity: 1;
              transition: opacity 0.7s 0.7s $ease-out-quart;
            }
          }
        }

        &.is-hidden {
          visibility: visible;

          .case__header,
          .case__text {
            opacity: 0;
            transition: opacity 0.7s $ease-in-quart;
          }

          .case__header,
          .case__text {
            opacity: 0;
            transform: translate(0, -20px);
            transition: transform 0.7s $ease-in-quart, opacity 0.7s $ease-in-quart;
          }

          .case__text {
            transition-delay: 0.1s;
            transition-timing: 0.6s;
          }

          .case__image {
            z-index: 0;

            span {
              opacity: 1;
            }
          }

          .case__author {
            visibility: hidden;
            transition: visibility 0s 0.7s;

            .vcard {
              opacity: 0;
              transition: opacity 0.3s 0.4s $ease-out-quart;
            }
          }
        }
      }

      @include mq($until: desktop) {
        display: none;
        height: auto!important;

        &.is-visible {
          display: block;
        }

        &.is-hidden {
          display: none;
        }
      }
    }
  }


  // Controls
  // =============================================================================

  .case-controls {
    @include position(absolute, 55px null null -95px);
    @include margin(-15px 0);

    button {
      display: block;
      @include margin(15px 0);
    }

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include margin(0 -15px);
      @include padding(55px 0 0);
      text-align: center;

      button {
        display: inline-block;
        @include margin(0 5px);
      }
    }

    @include mq($until: tablet) {
      @include padding(55px 0 0);
    }
  }
}
