/*------------------------------------*\
    BREADCRUMB
\*------------------------------------*/

.breadcrumb {
  @include margin(0 0 100px);
  @include clearfix();

  @include mq($until: desktop) {
    @include margin(0 0 45px);
  }


  // List & Actions
  // =============================================================================

  .menu__list {
    @include size(60%, auto);
    @include margin(0 -5px);
    float: left;
    font-size: 0;

    .menu__item {
      max-width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @include mq($until: desktop) {
      float: none;
      text-align: center;
      @include size(100%, auto);
      @include margin(null null 10px);
    }

    @include mq($until: tablet) {
      display: none;
    }
  }


  // Actions
  // =============================================================================

  .menu__actions {
    float: right;
    font-size: 0;
    text-align: right;
    @include margin(0 -10px);
    @include size(40%, auto);

    .menu__item {
      @include margin(0 10px);
    }

    @include mq($until: desktop) {
      float: none;
      text-align: center;
      @include size(100%, auto);
      @include margin(null 0 10px);
    }
  }


  // Items
  // =============================================================================

  .menu__item {
    @include margin(0 5px);
    @include font(lato, regular);
    display: inline-block;
    font-size: rem(13px);
    line-height: 1.2;
    letter-spacing: ls(10px);
    text-transform: none;
    text-align: left;
    text-decoration: none;
    color: #fff;

    svg {
      fill: color(brand-red);
    }
  }


  // Links
  // =============================================================================

  .menu__link {
    display: inline-block;
    text-decoration: none;
    color: tint(color(brand-grey), 25%);
    transition: color 0.2s $ease-out-quart;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      text-decoration: none;
    }
  }
}
