/*------------------------------------*\
    TAGS
\*------------------------------------*/

.tags-list {
  @include margin(0 -5px);

  @include mq($until: tablet) {
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.tags-item {
  display: inline-block;
}

.tag {
  display: inline-block;
  @include margin(0 5px 15px);
  @include padding(13px 20px);
  border-radius: 20px;
  background: darken(color(brand-white), 10%);
  @include font(lato, bold, family weight);
  font-size: rem(14px);
  line-height: 1;
  letter-spacing: ls(20px);
  text-transform: none;
  text-decoration: none;
  color: color(brand-black);
  transition: background 0.2s $ease-out-quart, color 0.2s $ease-out-quart;

  &--dark {
    background: color(brand-black);
    color: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background: color(brand-blue);
    color: #fff;
  }
}
