/*------------------------------------*\
    CONTENT - THREE COLUMN
\*------------------------------------*/

.content-three-column {
  // Title
  // =============================================================================

  .content__title {
    @include padding(35px 0 0);
    @include filet(color(brand-blue));
    border-top: 1px solid color(border);
    border-bottom: 1px solid color(border);

    @include mq($until: desktop) {
      @include margin(0 0 -1px);
    }
  }


  // Text
  // =============================================================================

  .content__text {
    @include padding(35px 0 0);
    border-top: 1px solid color(border);

    @for $i from 1 through $gs-count {
      $length: 100% / $i;

      @include mq($from: desktop) {
        &.col-#{$i} {
          @include border-pattern(color(border), $length, 100%, 1px, top);
          border-top: 0 none;
        }
      }
    }

    &.col-1 {
      border-top: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
