/*------------------------------------*\
    PAGE - REGION LIST
\*------------------------------------*/

.region-list {
  // Header
  // =============================================================================

  .page-header {
    &__title {
      @include margin(-50px 0 null);

      @include mq($until: desktop) {
        @include margin(0 0 null);
      }
    }

    &__aside {
      bottom: -130px;

      @include mq($until: desktop) {
        bottom: auto;
      }
    }
  }

  // Footer
  // =============================================================================

  .page-footer {
    background: color(brand-black-faded);
    border: 0 none;
    position: relative;
    padding: 100px 0;

    .vcard {
      @include margin(0 0 40px);
    }

    .fn {
      @include margin(40px 0 20px);
      font-size: rem(16px);
      font-weight: 700;
      line-height: 1.6;
    }

    .s_tel,
    .s_fax {
      strong {
        color: color(brand-blue);
      }
    }

    .s_tel {
      @include margin(5px 0 0);
    }

    .s_fax {
      @include margin(0 0 5px);
    }

    .email {
      font-weight: 700;
      color: #fff;

      a {
        color: inherit;
        text-decoration: none;
        transition: color 0.2s $ease-out-quart;

        &:hover,
        &:focus,
        &:active {
          color: color(brand-blue);
          text-decoration: none;
        }
      }
    }
  }


  // Body
  // =============================================================================

  .page-body {
    @include padding(0 0 30px);
    background: color(brand-white);

    @include mq($until: desktop) {
      @include padding(55px 0 0);
    }

    .center {
      @include clearfix();

      @include mq($until: desktop) {
        @include margin(0 -15px);
      }
    }
  }


  // Content
  // =============================================================================

  .content {
    @include clearfix();
    @include padding(0 0 null);
  }



  // Items
  // =============================================================================

  &__item {
    @include size(50%, auto);
    @include margin(0 0 -35px);
    float: left;

    &:nth-child(2n) {
      @include margin(130px 0 null);
      text-align: right;
    }

    &:last-child {
      @include margin(null 0 0);
    }

    &:hover {
      .region-list__figure::before {
        opacity: 0;
      }
      .region-list__figure::after {
        opacity: 1;
      }
      .region-list__title {
        color: #fff;
        background: color(brand-black);
      }
    }

    @include mq($until: desktop) {
      @include margin(0 0 30px);
      @include padding(0 15px);

      &:nth-child(2n) {
        @include margin(0 0 30px);
      }
    }

    @include mq($until: tablet) {
      @include size(100%, auto);
      @include margin(0 0 15px);

      &:nth-child(2n) {
        @include margin(0 0 15px);
      }
    }
  }


  // Figure
  // =============================================================================

  &__figure {
    @include position(relative);
    @include size(100%, 250px);
    @include margin(0 0 -35px);
    background-color: color(brand-black);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    z-index: 0;

    img {
      display: block;
      width: 100%;
    }

    &::before,
    &::after {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      transition: opacity 0.2s $ease-out-quart;
    }

    &::before {
      opacity: 1;
      background: linear-gradient(45deg, rgba(color(brand-blue), 0) 0%, transparent 50%),
                  linear-gradient(45deg, rgba(color(brand-blue), 1) 0%, transparent 50%)
    }

    &::after {
      opacity: 0;
      background: linear-gradient(45deg, rgba(color(brand-red), 0) 0%, transparent 50%),
                  linear-gradient(45deg, rgba(color(brand-red), 1) 0%, transparent 50%)
    }

    @include mq($until: tablet) {
      @include margin(0);
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include position(relative);
    @include padding(25px 30px);
    @include margin(0);
    display: inline-block;
    background: #fff;
    border-radius: 2px;
    @include font(lato, bold, family weight);
    font-size: rem(20px);
    line-height: 1;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    color: color(brand-black);
    transition: background 0.2s $ease-out-quart, color 0.2s $ease-out-quart;
    z-index: 1;

    .svg {
      float: right;
      position: relative;
      @include margin(5px 0 0 20px);
    }

    svg {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      fill: color(brand-red);
    }

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
    }

    @include mq($until: tablet) {
      display: block;
    }
  }
}
