/*------------------------------------*\
    SHARING
\*------------------------------------*/

.sharing {
  @include clearfix();
  @include position(absolute, null 50px 50px null);
  transform-origin: right bottom;
  transform: rotate(90deg) translate(100%, 100%);
  z-index: 40;

  @include mq($until: 1120px) {
    @include position(relative, auto);
    @include margin(80px 0 -75px);
    @include size(100%, auto);
    transform: none;
  }

  @include mq($until: tablet) {
    @include margin(80px 0 -60px);
  }


  // Label
  // =============================================================================

  &__label {
    float: left;
    @include margin(0 10px 0 0);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 40px;
    letter-spacing: ls(200px);
    text-transform: uppercase;
    text-decoration: none;
    color: color(brand-black);

    &::before {
      content: '';
      float: left;
      display: inline-block;
      @include size(100px, 1px);
      @include margin(20px 20px 0 0);
      background: color(border);
    }

    @include mq($until: 1120px) {
      display: none;
    }
  }

  // List
  // =============================================================================

  &__list {
    float: left;
    font-size: 0;
    text-align: center;

    @include mq($until: 1120px) {
      display: block;
      width: 100%;
    }
  }


  // Items
  // =============================================================================

  &__item {
    display: inline-block;
    @include size(40px);
    @include margin(0 5px);
    transform-origin: center;
    transform: rotate(-90deg);

    @include mq($until: 1120px) {
      transform: none;
    }
  }


  // Links
  // =============================================================================

  &__link {
    display: inline-block;
    @include position(relative);
    @include size(100%);
    @include padding(0);
    @include margin(0);
    background: transparent;
    border: 1px solid color(border);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: background 0.2s $ease-out-quad, border 0.2s $ease-out-quad;

    .svg {
      @include position(absolute, 50% null null 50%);
      transform-origin: 50%;
      transform: translate(-50%, -50%);

      svg {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        fill: color(brand-blue);
        transition: fill 0.2s $ease-out-quad;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .svg {
        @each $social in $socials {
          &.icon-#{$social} {
            svg {
              fill: color(social-#{$social});
            }
          }
        }
      }
    }

    @include mq($until: 1120px) {
      @each $social in $socials {
        &.#{$social} {
          background: color(social-#{$social});
          border-color: color(social-#{$social});

          svg {
            fill: #fff;
            transition: none;
          }

          &:hover,
          &:focus,
          &:active {
            background: shade(color(social-#{$social}), 10%);
            border-color: shade(color(social-#{$social}), 10%);

            svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
