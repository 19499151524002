/*------------------------------------*\
    MARKETS
\*------------------------------------*/

.markets {
  @include margin(0 (-$gs-gutter / 2));
  @include clearfix();


  // Items
  // =============================================================================

  &__item {
    float: left;
    @include position(relative);
    @include size(calc(33.333% - #{$gs-gutter}), 100px);
    @include margin(0 ($gs-gutter / 2) -1px);
    border-top: 1px solid color(border);
    border-bottom: 1px solid color(border);

    @include mq($until: desktop) {
      @include size(calc(50% - #{$gs-gutter}), 100px);
    }

    @include mq($until: tablet) {
      @include size(100%, 100px);
    }
  }


  // Links
  // =============================================================================

  &__link {
    display: block;
    @include position(absolute, 50% 0 null 0);
    @include padding(40px 40px 40px 75px);
    @include font(lato, bold, family weight);
    font-size: rem(14px);
    line-height: 1.7;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    color: color(brand-black);
    transform-origin: 50%;
    transform: translate(0, -50%);

    .svg {
      @include position(absolute, 50% null null 15px);
      display: block;
      transform-origin: 50%;
      transform: translateY(-50%);

      &.icon-caret-right {
        left: auto;
        right: 15px;

        svg {
          fill: rgba(color(brand-red), 0);
        }
      }
    }

    svg {
      display: block;
      fill: color(brand-blue);
      transition: fill 0.2s $ease-out-quad;
    }

    &:hover,
    &:focus,
    &:active {
      color: color(brand-black);
      text-decoration: none;

      .svg {
        opacity: 1;

        &,
        &.icon-caret-right {
          svg {
            fill: color(brand-red);
          }
        }
      }
    }
  }
}
