/*------------------------------------*\
    GATEWAY
\*------------------------------------*/

.gateway {
  background: #fff;
  @include padding(45px);
  transition: box-shadow 0.8s $ease-out-quart, transform 0.8s $ease-out-quart;

  &[data-href]:hover {
    transform: translate(0, -3px);
    box-shadow: 0 2px 10px rgba(color(brand-black), 0.05);
  }

  &--dark {
    background: color(brand-black);

    .gateway__title {
      color: #fff;
    }
    .gateway__text p {
      color: tint(color(brand-grey), 25%);
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include margin(0 0 20px);
    @include font(lato, regular, family weight);
    font-size: rem(25px);
    line-height: 1.3;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(brand-black);

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }


  // Text
  // =============================================================================

  &__text {
    min-height: 140px;
    @include margin(0 0 20px);

    @include mq($until: tablet) {
      min-height: 0;
    }
  }
}
