/*------------------------------------*\
    PAGE - SECTOR DOCUMENT
\*------------------------------------*/

.sector-documents {
  // grid
  // =============================================================================

  .grid {
    &__title {
      @include filet(color(brand-blue));
      border-bottom: 1px solid color(border);
    }
  }


  // Posts
  // =============================================================================

  .post {
    &__figure {
      display: none;
    }
  }
}
