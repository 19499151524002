/*------------------------------------*\
    HEADER - MENU GATEWAY
\*------------------------------------*/

.menu-gateway {
  @include position(relative);
  @include padding(0);
  @include margin(10px 0 0);

  &:hover {
    .menu__list {
      display: block;
    }
    .menu__selected {
      background: color(brand-black-faded);
    }
  }

  @include mq($until: desktop) {
    @include margin(0 auto);
    text-align: center;

    &:hover {
      .menu__list {
        display: inline-block;
      }
    }
  }


  // List
  // =============================================================================

  .menu__list {
    @include position(absolute, 100% null null 0);
    @include size(auto, auto);
    @include margin(0);
    min-width: 100%;
    background: color(brand-black-faded);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    //text-align: left;
    z-index: 999;
    display: none;

    @include mq($until: desktop) {
      @include position(relative, auto);
      display: inline-block;
      background: none;
      text-align: center;
    }
  }


  // Items & Selected
  // =============================================================================

  .menu__selected,
  .menu__item {
    display: block;
    @include padding(0 15px);
    @include font(lato, bold, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(60px);
    text-transform: uppercase;
    text-decoration: none;
    //text-align: left;
    color: tint(color(brand-grey), 35%);
  }

  .menu__selected {
    @include padding(15px 30px 15px 15px);
    position: relative;
    border-radius: 2px;
    font-weight: 900;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s $ease-out-quart;

    .svg {
      @include position(absolute, 50% 15px null null);
      transform: translate(0, -50%);
    }

    svg {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      fill: color(brand-blue);
    }

    @include mq($until: desktop) {
      display: none;
    }
  }


  // Links
  // =============================================================================

  .menu__link {
    display: block;
    line-height: 40px;
    text-decoration: none;
    color: inherit;
    transition: color 0.2s $ease-out-quart;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      text-decoration: none;
    }
  }
}
