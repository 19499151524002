/*------------------------------------*\
    PAGE - HELP
\*------------------------------------*/

.help {
  // grid
  // =============================================================================

  .grid {
    &__title {
      @include filet(color(brand-blue));
      border-bottom: 1px solid color(border);
    }

    .post,
    .call-to-action {
      @include margin(0 0 30px);

      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }
  }


  // Posts
  // =============================================================================

  .post {
    &__figure {
      display: none;
    }
  }


  // List
  // =============================================================================

  .content-list {
    .content__title {
      @include padding(35px null null);
      border-bottom: 1px solid color(border);
      border-top: 1px solid color(border);
    }

    .content__text {
      border-top: 1px solid color(border);

      &::before {
        display: none;
      }
    }

    .content__item {
      @include margin(null 0);
      border-bottom: 1px solid color(border);

      &::after,
      &::before {
        display: none;
      }

      &:last-child {
        border: 0 none;
        padding-bottom: 0;
      }

      .title {
        @include size(100%, auto);
        @include padding(null 0);
      }
    }
  }


  // Form
  // =============================================================================

  .help-form {
    @include padding(0 null null);

    .is-hidden {
      display: none;
    }
  }


  // Footer
  // =============================================================================

  .page-footer {
    @include clearfix();
    background: color(brand-white);
    border: 0 none;
    padding: 30px 0 0;
  }
}

@import 'help-landing';
