/*------------------------------------*\
    HEADER - BASE
\*------------------------------------*/

.site-header {
  @include position(relative);
  @include padding(60px null null);

  @include mq($until: desktop) {
    @include padding(30px null);
  }


  // Mobile Wrapper
  // =============================================================================

  .mobile-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @include mq($until: desktop) {
      display: none;
      @include position(fixed, 0 null null 0);
      @include size(100%);
      background: rgba(color(brand-black), 0.9);
      z-index: 50;

      .is-menu-open & {
        display: block;
      }
    }
  }


  // Logo
  // =============================================================================

  .logo {
    float: left;
    @include size(131px, 100px);
    @include margin(0);
    @include padding(0);

    &--mobile {
      display: none;
      float: none;
      @include margin(0 auto);
    }

    @include mq($until: desktop) {
      display: none;

      &--mobile {
        display: block;
      }
    }

    a {
      @include size(100%);
      display: inline-block;
      background-color: transparent;
      background-image: url('../assets/images/logo.png');
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 131px 100px;
      text-indent: -9999px;
    }
  }


  // Hight Level
  // =============================================================================

  .hight-level {
    @include clearfix();
    @include padding(40px null);
    background: #fff;

    @include mq($until: desktop) {
      background: none;
      padding-top: 115px;
    }
  }


  // Low Level
  // =============================================================================

  .low-level {
    @include position(absolute, 0 null null 0);
    @include size(100%, 60px);
    @include clearfix();
    z-index: 1;

    &::before {
      content: '';
      display: block;
      @include position(absolute, 0 null null 0);
      @include size(calc(50% + (#{$gs-container} / 2)), 100%);
      background: color(brand-black);
      z-index: -1;
    }

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include size(100%, auto);

      &::before {
        display: none;
      }

      .u-pull-left,
      .u-pull-right {
        float: none;
      }
    }
  }

  // Admin
  // =============================================================================
  .admin {
    position: absolute;
    top: 0;
    left: 1em;
    line-height: 60px;
  }


  // Login
  // =============================================================================

  .login {
    float: left;
    background: color(brand-red);
    @include margin(0);
    @include padding(0 20px);
    @include font(lato, bold, family weight);
    font-size: rem(12px);
    line-height: 60px;
    letter-spacing: ls(20px);
    text-align: center;
    text-transform: none;
    text-decoration: none;
    color: #fff;
    transition: background 0.2s $ease-out-quart;

    @include mq($until: desktop) {
      float: none;
      display: block;
      @include size(150px, auto);
      @include margin(15px auto 30px);
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background: tint(color(brand-red), 15%);
    }
  }
}
