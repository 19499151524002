/*------------------------------------*\
    CONTENT - QUOTE
\*------------------------------------*/

.content-quote {
  // Colors
  // =============================================================================

  &.blue {
    .content__text {
      color: color(brand-blue);
    }
  }

  &.red {
    .content__text {
      color: color(brand-red);
    }
  }


  // Title
  // =============================================================================

  .content__title {
    @include margin(0 0 20px);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(100px);
    text-transform: uppercase;
    text-decoration: none;
    color: color(brand-black);
  }


  // Text
  // =============================================================================

  .content__text {
    @include margin(0 0 -10px);
    @include padding(40px 0 0);
    @include border-pattern(color(border), 50%, 100%, 1px, top);
    @include font(merriweather, italic, family weight style);
    font-size: rem(25px);
    line-height: 1.65;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(text);

    @include mq($until: tablet) {
      @include padding(30px 0 0);
      font-size: rem(20px);
      border-top: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }
  }


  // Aside
  // =============================================================================

  .content__aside {
    @include margin(30px 0 0);
  }
}
