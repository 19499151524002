/*------------------------------------*\
    PAGE - COUNTRY LANDING
\*------------------------------------*/

.country-landing {
  // Lead
  // =============================================================================

  .content-lead {
    @include position(relative);

    &::before {
      content: '';
      display: block;
      @include position(absolute, 0 0 null null);
      @include size(calc(50% - 175px), 100%);
      background: color(brand-white);
    }

    .call-to-action {
      background: none;
      padding-top: 0;
      padding-bottom: 0;

      &__title {
        color: color(brand-black);
      }

      &__text {
        p {
          color: rgba(color(text), 0.8);
        }
      }
    }

    .simple-link {
      color: color(brand-blue);

      &::before {
        background: rgba(color(brand-black), 0.15)
      }

      &:hover,
      &:focus,
      &:active {
        color: tint(color(brand-blue), 15%);
      }
    }

    @include mq($until: desktop) {
      &::before {
        display: none;
      }

      .call-to-action {
        background: color(brand-white);
        padding-top: 45px;
        padding-bottom: 45px;
      }
    }

    @include mq($until: desktop) {

      .call-to-action {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }


  // Charts
  // =============================================================================

  .chart {
    @include margin(0 0 70px);

    &:last-child {
      @include margin(0 0 35px);
    }

    @include mq($until: desktop) {
      @include margin(0 0 40px);
    }

    @include mq($until: tablet) {
      @include margin(0 0 30px);

      &:last-child {
        @include margin(0);
      }
    }
  }


  // Footer
  // =============================================================================

  .page-footer {
    @include clearfix();
    @include padding(75px 0);
    background: color(brand-black-faded);
    border: 0 none;
    min-height: 500px;

    @include mq($until: desktop) {
      @include padding(55px 0);
    }

    @include mq($until: tablet) {
      @include padding(40px 0);
    }

    &__aside {
      overflow: visible;
      height: auto;
      bottom: -50px;

      @include mq($until: desktop) {
        bottom: 0;
      }
    }
  }
}
