/*------------------------------------*\
    PAGE - BODY
\*------------------------------------*/

.page-body {
  //@include position(relative);
  @include size(100%, auto);
  @include padding(0);
  min-height: 390px;
  background: #fff;
}
