/*------------------------------------*\
    HEADER - MENU LANG
\*------------------------------------*/

.menu-lang {
  float: left;
  @include position(relative);
  @include padding(0);
  @include margin(10px 20px 0);

  &:hover {
    .menu__list {
      display: block;
    }
    .menu__selected {
      color: #fff;
    }
  }

  @include mq($until: desktop) {
    float: none;
    text-align: center;
    @include margin(0 auto);
    @include padding(15px 0);

    &:hover {
      .menu__list {
        display: inline-block;
      }
    }
  }


  // List
  // =============================================================================

  .menu__list {
    @include position(absolute, 100% null null 0);
    @include size(100%, auto);
    @include margin(0);
    background: color(brand-black-faded);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    text-align: center;
    z-index: 999;
    display: none;

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include size(auto);
      display: inline-block;
      background: transparent;
    }
  }


  // Items & Selected
  // =============================================================================

  .menu__selected,
  .menu__item {
    display: block;
    @include font(lato, bold, family weight);
    font-size: rem(9px);
    line-height: 1;
    letter-spacing: ls(60px);
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: tint(color(brand-grey), 35%);

    @include mq($until: desktop) {
      display: inline-block;
      font-size: rem(10px);
      font-weight: 900;
      color: #fff;
      @include margin(0 5px);
    }
  }

  .menu__selected {
    @include padding(15px);
    background: color(brand-black-faded);
    border-radius: 2px;
    line-height: 1;
    cursor: pointer;
    transition: color 0.2s $ease-out-quart;

    @include mq($until: desktop) {
      display: none;
    }
  }


  // Links
  // =============================================================================

  .menu__link {
    display: block;
    line-height: 40px;
    text-decoration: none;
    color: inherit;
    transition: color 0.2s $ease-out-quart;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      text-decoration: none;
    }

    @include mq($until: desktop) {
      display: inline-block;
    }
  }
}
