/*------------------------------------*\
    PAGE - FOOTER
\*------------------------------------*/

.page-footer {
  @include position(relative);
  @include padding(100px 0);
  @include margin(0);
  border-bottom: 165px solid color(brand-black);
  background: color(brand-black-faded);
  background: linear-gradient(45deg, rgba(color(brand-black-faded), 0) 35%, color(brand-red) 300%),
              linear-gradient(45deg, rgba(color(brand-black-faded), 1) 70%, color(brand-red) 120%);

  @include mq($until: desktop) {
    @include padding(50px 0);
    border: 0 none;
  }

  @include mq($until: tablet) {
    @include padding(40px 0);
  }


  // Title
  // =============================================================================

  .title {
    @include margin(0 0 70px);
    @include border-enclosure(#fff);
    font-size: rem(28px);
    font-weight: 400;
    color: #fff;

    @include mq($until: desktop) {
      @include margin(0 0 55px);
    }

    @include mq($until: tablet) {
      @include margin(0 0 30px);
      font-size: rem(23px);
    }
  }


  // Content
  // =============================================================================

  &__content {
    @include size(290px, auto);
    @include font(lato, regular, family weight);
    font-size: rem(14px);
    line-height: 2;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: tint(color(brand-grey), 10%);

    .title {
      border-bottom: 1px solid rgba(#fff, 0.1);
      @include margin(0 0 25px);
      @include filet(color(brand-blue));
      @include font(lato, bold, family weight);
      font-size: rem(26px);
      line-height: 1.3;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      text-align: left;
      color: #fff;
    }

    @include mq($until: desktop) {
      @include size(100%, auto);
    }
  }


  // Aside
  // =============================================================================

  &__aside {
    $offset: ($gs-container / 2) - 320px;
    $margin: ($offset * -1);

    @include position(absolute, 0 0 0 50%);
    @include margin(0 0 0 #{$margin});
    @include size(calc(50% + #{$offset}), 100%);
    background: color(brand-black);
    overflow: hidden;
    z-index: 1;

    .map {
      display: block;
      @include size(100%);
      @include margin(0);
      @include padding(0);
    }

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include margin(30px 0 0);
      @include size(100%, auto);

      .map {
        @include size(100%, 300px);
      }
    }
  }
}
