/*------------------------------------*\
    FOOTER - BASE
\*------------------------------------*/

.site-footer {
  // Hight Level
  // =============================================================================

  .hight-level {
    @include clearfix();
    @include padding(100px null 90px);
    background: color(brand-black);

    @include mq($until: desktop) {
      @include padding(50px 0);

      .col {
        @include margin(0 0 30px);
        text-align: center;

        &:last-child {
          @include margin(0);
        }
      }
    }
  }


  // Low Level
  // =============================================================================

  .low-level {
    @include clearfix();
    @include padding(70px 0 60px);
    background: #fff;

    @include mq($until: desktop) {
      @include padding(50px 0);

      .col {
        @include margin(0 0 30px);
        text-align: center;

        &:last-child {
          @include margin(0);
        }
      }
    }
  }


  // VAT Number
  // =============================================================================

  .vat-number {
    @include margin(30px 0 0 5px);
    @include font(lato, regular);
    font-size: rem(13px);
    line-height: 1;
    letter-spacing: 0;
    text-transform: uppercase;
    //text-align: left;
    color: rgba(color(brand-black), 0.4);
  }


  // VCARD
  // =============================================================================

  .vcard {
    @include font(lato, regular, family weight);
    font-size: rem(13px);
    line-height: 2;
    letter-spacing: ls(10px);
    text-transform: none;
    //text-align: left;
    color: rgba(color(brand-black), 0.4);

    .fn {
      @include margin(0 0 18px);
      @include font(merriweather, bold, family weight);
      color: color(brand-black);
    }
  }


  // Social
  // =============================================================================

  .social {
    @include margin(0 -5px 38px);
    @include padding(0);
    font-size: 0;

    &__item {
      display: inline-block;
      @include size(40px);
      @include margin(0 5px);
    }

    &__link {
      display: inline-block;
      @include position(relative);
      @include size(100%);
      border: 1px solid color(border);
      border-radius: 50%;

      .svg {
        @include position(absolute, 50% null null 50%);
        transform-origin: 50%;
        transform: translate(-50%, -50%);

        svg {
          transition: fill 0.2s $ease-out-quart;
        }

        @each $entry in $socials {
          &.icon-#{$entry} {
            svg {
              fill: rgba(color(brand-black), 0.4);
            }
          }
        }
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;

        .svg {
          @each $entry in $socials {
            &.icon-#{$entry} {
              svg {
                fill: color(social-#{$entry});
              }
            }
          }
        }
      }
    }
  }


  // Dogstudio
  // =============================================================================

  .dogstudio {
    @include position(absolute, null 0 0 null);
    display: inline-block;
    opacity: 0.6;
    transition: opacity 0.2s $ease-out-quart;

    @include mq($until: desktop) {
      @include position(relative);
      @include margin(30px 0 0);
    }

    svg {
      fill: color(brand-black);
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
}
