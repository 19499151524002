/*------------------------------------*\
    SUB-MENU
\*------------------------------------*/

.sub-menu {
  @include margin(140px 0 0);

  @include mq($until: 1120px) {
    margin-bottom: -20px;
  }

  @include mq($until: desktop) {
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 20px;
    margin-bottom: -40px;
    margin-top: 80px;
    -webkit-overflow-scrolling: touch;
  }

  @include mq($until: tablet) {
    margin-top: 60px;
  }

  // List
  // =============================================================================

  .menu__list {
    display: block;
    @include size(100%, auto);
    @include margin(0 -15px -15px);
    @include padding(0);
    @include clearfix();
  }


  // Items
  // =============================================================================

  .menu__item {
    float: left;
    display: inline-block;
    @include size(33.333%, 70px);
    @include padding(0 15px);
    @include margin(null null 15px);
    @include font(lato, black, family weight);
    font-size: rem(14px);
    line-height: 16px;
    letter-spacing: ls(20px);
    text-transform: none;
    text-decoration: none;
    color: #fff;
    white-space: normal;
    vertical-align: top;

    &::before {
      content: '';
      display: block;
      @include size(100%, 2px);
      @include margin(0 0 15px);
      background: #fff;
      opacity: 0.2;
    }

    @include mq($until: tablet) {
      @include size(50%, auto);
      margin-bottom: 30px;
    }

    @include mq($until: 420px) {
      @include size(100%, auto);
    }
  }


  // Links
  // =============================================================================

  .menu__link {
    display: block;
    @include position(relative);
    @include margin(-17px 0 0);
    @include padding(20px 0 0);
    @include font(merriweather, italic);
    font-size: rem(12px);
    line-height: 1.5;
    letter-spacing: ls(20px);
    text-transform: none;
    text-decoration: none;
    color: #fff;

    &::before,
    &::after {
      content: '';
      display: block;
      @include position(absolute, 0 null null 0);
      @include size(100%, 2px);
      @include margin(0 0 15px);
      background: color(brand-blue);
    }

    &::after {
      background: #fff;
      transform-origin: left center;
      transform: scale(0, 1);
      transition: transform 0.2s $ease-out-quad;
      z-index: 1;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      text-decoration: none;

      &::after {
        transform: scale(1, 1);
      }
    }
  }
}
