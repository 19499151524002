/*------------------------------------*\
    VARIABLES
\*------------------------------------*/


// Kennel Settings
// =============================================================================

$base-url: '../assets/';
$base-font-size: 14px;
$base-line-height: 2;
$modular-scale-ratio: $perfect-fourth;
$modular-scale-base: ($base-font-size, 28px);


// Overide Default Color Palette
// =============================================================================

$default-colors: (
  brand-white        : #f1f1f1,
  brand-black        : #1e2229,
  brand-black-faded  : #292d34,
  brand-red          : #ed4635,
  brand-blue         : #548fca,
  brand-blue-faded   : #2B2F36,
  brand-grey         : #787a7f,
  brand-grey-shaded  : #dbdcdd,
  brand-grey-lighten : #c9c9c9,

  white           : #fff,
  black           : #000,
  blue            : #1385c8,

  heading         : #1e2229,
  text            : #787a7f,
  link            : #548fca,
  border          : #dbdcdd,

  social-twitter  : #04aceb,
  social-facebook : #3b5998,
  social-linkedin : #0085af,
  social-youtube  : #CD201F,
  social-instagram: #3F729B,

  alert-valid     : #548fca,
  alert-warning   : orange,
  alert-error     : #f86455,
);


// Socials
// =============================================================================

$socials : ('facebook', 'twitter', 'instagram', 'youtube', 'linkedin');


// Grid System
// =============================================================================

$gs-container  : 930px;
$gs-gutter     : 30px;
$gs-count      : 12;
$gs-column     : (100% / $gs-count);


// Font Stack
// =============================================================================

$base-font-stack: (
  // Sans-serif
  lato: (
    light: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 300,
      style: normal,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Light',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    light-italic: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 300,
      style: italic,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Light-Italic',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    regular: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 400,
      style: normal,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Regular',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    italic: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 400,
      style: italic,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Italic',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    bold: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 700,
      style: normal,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Bold',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    bold-italic: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 700,
      style: italic,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Bold-Italic',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    black: (
      family: (Lato, Helvetica, Arial, sans-serif),
      weight: 900,
      style: normal,
      cap-height: 0.68,
      file: (
        family: 'Lato',
        path: 'Lato/Lato-Black',
        formats: (eot woff woff2 ttf svg)
      )
    ),
  ),

  // Serif
  merriweather: (
    regular: (
      family: (Merriweather, Times, serif),
      weight: 400,
      style: normal,
      cap-height: 0.68,
      file: (
        family: 'Merriweather',
        path: 'Merriweather/Merriweather-Regular',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    italic: (
      family: (Merriweather, Times, serif),
      weight: 400,
      style: italic,
      cap-height: 0.68,
      file: (
        family: 'Merriweather',
        path: 'Merriweather/Merriweather-Italic',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    bold: (
      family: (Merriweather, Times, serif),
      weight: 700,
      style: normal,
      cap-height: 0.68,
      file: (
        family: 'Merriweather',
        path: 'Merriweather/Merriweather-Bold',
        formats: (eot woff woff2 ttf svg)
      )
    ),
    bold-italic: (
      family: (Merriweather, Times, serif),
      weight: 700,
      style: italic,
      cap-height: 0.68,
      file: (
        family: 'Merriweather',
        path: 'Merriweather/Merriweather-Bold-Italic',
        formats: (eot woff woff2 ttf svg)
      )
    ),
  ),

  // Monospace
  code: (
    regular: (
      family: (Menlo, monospace),
      weight: 400,
      style: normal,
      cap-height: 0.72
    )
  )
);


// Responsive Settings
// =============================================================================

$mq-responsive: true;
$mq-breakpoints: (
  mobile:           320px,
  tablet:           740px,
  desktop:          930px,
  wide:             1300px
);
$mq-static-breakpoint: desktop;
$mq-show-breakpoints: (mobile, tablet, desktop, wide);
