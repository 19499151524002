/*------------------------------------*\
    PAGE - COUNTRY CONTACTS
\*------------------------------------*/

.country-contacts {
  // Grid
  // =============================================================================

  .grid {
    &__title {
      border-bottom: 0 none;

      &::after {
        display: none;
      }
    }
  }
}
