/*------------------------------------*\
    HEADER - MENU PRIMARY
\*------------------------------------*/

.menu-primary {
  float: right;
  @include padding(0);
  @include margin(50px null null);

  @include mq($until: desktop) {
    float: none;
  }


  // List
  // =============================================================================

  .menu__list {
    @include clearfix();
    @include margin(0 -15px);
    //font-size: 0;
    text-align: right;

    @include mq($until: desktop) {
      text-align: center;
    }
  }


  // Items
  // =============================================================================

  .menu__item {
    float: left;
    @include margin(0 15px);
    @include font(lato, bold, family weight);
    font-size: rem(16px);
    line-height: 1;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    color: color(brand-black);

    .search-button {
      @include position(relative);
      @include size(20px);
      display: block;
      margin: 0;
      padding: 0;
      opacity: 0.5;
      cursor: pointer;
      transition: opacity 0.2s $ease-out-quart;

      .svg {
        @include position(absolute, 50% null null 50%);
        @include margin(-2px 0 0);
        transform-origin: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }

    @include mq($until: desktop) {
      display: block;
      float: none;
      color: #fff;

      .search-button {
        @include margin(null auto);
      }

      svg {
        fill: #fff;
      }

      &:last-child {
        .menu__link::after {
          display: none;
        }
      }
    }
  }


  // Links
  // =============================================================================

  .menu__link {
    display: inline-block;
    text-decoration: none;
    color: inherit;

    &::after {
      content: '';
      display: block;
      @include size(50px, 5px);
      @include margin(15px auto 0);
      background-color: color(brand-black);
      opacity: 0;
      transform-origin: 50%;
      transform: scale(0, 1);
      transition: transform 0.3s $ease-out-quart, opacity 0.2s $ease-out-quart;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      text-decoration: none;

      &::after {
        opacity: 1;
        transform: scale(1, 1);
      }
    }

    @include mq($until: desktop) {
      &::after {
        width: 25px;
        height: 3px;
        margin-top: 25px;
        margin-bottom: 25px;
        background: color(brand-blue);
        transform: scale(1, 1);
        opacity: 1;
      }
    }
  }
}
