/*------------------------------------*\
    CONTENT - MEDIA
\*------------------------------------*/

.content-media {
  @include position(relative);
  @include padding(0 0 140px);
  z-index: 1;

  &.no-image {
    padding-bottom: 60px;
  }


  // Background
  // =============================================================================

  .content__background {
    @include position(absolute, null null 0 0);
    @include size(100%, calc(100% - 140px));
    background: color(brand-black);
    overflow: hidden;
    z-index: -1;

    @include mq($until: desktop) {
      @include size(100%);
    }

    .image {
      display: block;
      @include size(100%);
      @include margin(0);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left top;
      filter: blur(5px);
      opacity: 0.2;
    }
  }


  // Picture
  // =============================================================================

  .picture {
    display: block;
    width: 100%;
  }


  // Fluid Iframe
  // =============================================================================
  .fluid-iframe {
    iframe {
      display: block;
    }
  }
}
