/*------------------------------------*\
    CONTENT - MAP
\*------------------------------------*/

.content-map {
  border-top: 90px solid color(brand-white);

  &.content--grey {
    border-color: #fff;
  }


  // Map
  // =============================================================================

  .map {
    @include size(100%, 415px);
    @include margin(-145px 0 0);

    @include mq($until: desktop) {
      @include size(100%, 290px);
    }
  }
}
