/*------------------------------------*\
    PAGE - HEADER
\*------------------------------------*/

.page-header {
  @include position(relative);
  @include size(100%, auto);
  @include padding(55px 0);
  min-height: 400px;
  background: color(brand-black);
  background: linear-gradient(45deg, rgba(color(brand-blue), 0) 0%, color(brand-black) 80%),
              linear-gradient(45deg, rgba(color(brand-blue), 1) 0%, color(brand-black) 80%);

  @include mq($until: 1120px) {
    min-height: 0;
  }

  @include mq($until: tablet) {
    @include padding(40px 0);
  }


  // Content
  // =============================================================================

  &__content {
    @include padding(0 130px 0 50px);
    @include filet(color(brand-red), 130px, 2px, top, 35px 0 35px -150px);
    @include font(lato, regular, family weight);
    font-size: rem(14px);
    line-height: 2;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: tint(color(brand-grey), 25%);

    .title {
      @include margin(0 0 25px);
      @include font(lato, bold, family weight);
      font-size: rem(20px);
      line-height: 1.3;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      color: #fff;
    }

    .button {
      @include margin(30px 0 0);
    }

    @include mq($until: desktop) {
      @include padding(0);

      &::before {
        visibility: hidden;
        margin-top: 0;
      }
    }
  }


  // Aside
  // =============================================================================

  &__aside {
    @include position(absolute, 125px -100px 0 50%);
    max-width: 565px;
    background: color(brand-black-faded);
    overflow: hidden;
    z-index: 1;

    .image {
      display: block;
      @include size(100%);
      @include margin(0);
      @include padding(0);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .map {
      display: block;
      @include size(100%);
      @include margin(0);
      @include padding(0);
    }

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include size(100%, 400px);
      max-width: 100%;
    }

    @include mq($until: tablet) {
      @include size(100%, 290px);
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include margin(100px 0 0);
    @include font(lato, light, family weight);
    font-size: rem(60px);
    line-height: 1;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    color: #fff;

    @include mq($until: desktop) {
      @include margin(0);
    }

    @include mq($until: tablet) {
      font-size: rem(40px);
    }

    strong {
      font-weight: 400;
    }
  }


  // Subtitle
  // =============================================================================

  &__subtitle {
    @include margin(10px 0 0);
    @include font(lato, light, family weight);
    font-size: rem(45px);
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: #fff;

    @include mq($until: tablet) {
      font-size: rem(30px);
    }

    strong {
      font-weight: 400;
    }
  }


  // Form
  // =============================================================================

  &__form {
    .form-control {
      @include padding(null 50px null null);
    }

    .form-submit {
      @include position(absolute, null 0 0 null);
      @include size(50px);
      cursor: pointer;

      .svg {
        @include position(absolute, 50% null null 50%);
        transform-origin: 50%;
        transform: translate(-50%, -50%);
      }

      svg {
        fill: rgba(#fff, 0.5);
        transition: fill 0.2s $ease-out-quart;
      }

      &:hover,
      &:focus,
      &:active {
        svg {
          fill: #fff;
        }
      }
    }
  }


  // Social
  // =============================================================================

  &__social {
    @include margin(-47px -5px 20px);

    .social__item {
      display: inline-block;
      @include margin(0 5px);
    }

    .social__link:hover,
    .social__link:focus,
    .social__link:active {
      text-decoration: none;

      svg {
        fill: #fff;
      }
    }

    svg {
      fill: color(brand-red);
      transition: fill 0.2s $ease-out-quart;
    }

    @include mq($until: desktop) {
      @include margin(-20px null null);
    }
  }
}
