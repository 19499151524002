/*------------------------------------*\
    CAROUSEL
\*------------------------------------*/

.carousel {
  @include position(relative);

  &--reverse {
    .carousel__controls {
      left: auto;
      right: -95px;

      @include mq($until: desktop) {
        @include position(relative, auto);
      }
    }
  }


  // Controls
  // =============================================================================

  &__controls {
    @include position(absolute, 0 null null -95px);
    @include margin(-15px 0);

    button {
      display: block;
      @include margin(15px 0);
    }

    @include mq($until: desktop) {
      @include position(relative, auto);
      @include margin(30px -15px);
      text-align: center;

      button {
        display: inline-block;
        @include margin(0 5px);
      }
    }
  }


  // Children
  // =============================================================================

  &__child {
    @include position(absolute, 0 null null 0);
    z-index: 0;

    @include mq($until: desktop) {
      top: 88px;
    }

    .col {
      opacity: 0;
      transform: translate(0, 20px);
      transition: transform 0.5s $ease-out-quad, opacity 0.5s $ease-out-quad;

      @for $i from 1 through $gs-count {
        &:nth-child(#{$i}) {
          transition-delay: ($i - 1) * 0.1s;
        }
      }

      @include mq($until: desktop) {
        transform: translate(0, 0);
      }
    }

    .post,
    .call-to-action,
    .member {
      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }

    &.is-visible {
      @include position(relative, auto);
      z-index: 1;

      .col {
        opacity: 1;
        transform: translate(0, 0);

        @for $i from 1 through $gs-count {
          $base: 0.5s;

          &:nth-child(#{$i}) {
            transition-delay: $base + (($i - 1) * 0.1s);
          }
        }
      }
    }

    &.is-hidden {
      z-index: 1;

      .col {
        opacity: 0;
        transform: translate(0, -20px);

        @include mq($until: desktop) {
          transform: translate(0, 0);
        }
      }
    }
  }
}
