/*------------------------------------*\
    STATS
\*------------------------------------*/

.stats {
  display: block;
  @include padding(65px 0 65px 65px);
  @include margin(0 0 0 240px);
  @include clearfix();
  max-width: 690px;
  background: color(brand-black);

  @include mq($until: desktop) {
    max-width: 100%;
    margin-left: 0;
    padding: 55px 0;
  }

  @include mq($until: tablet) {
    padding: 40px 0;
  }


  // Simple link
  // =============================================================================

  .simple-link {
    display: inline-block;
    margin-top: 50px;
  }


  // Subhead
  // =============================================================================

  &__subhead {
    @include font(lato, black, family weight);
    font-size: rem(9px);
    line-height: 1;
    letter-spacing: ls(150px);
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(#fff, 0.5);
  }


  // Title
  // =============================================================================

  &__title {
    @include filet(color(brand-blue), 50px, 5px, bottom, 30px 0 0);
    @include font(lato, bold, family weight);
    font-size: rem(25px);
    line-height: 1.7;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: #fff;
  }


  // List
  // =============================================================================

  &__list {
    @include margin(0 (-$gs-gutter / 2));

    @include mq($until: tablet) {
      @include margin(0);
    }
  }


  // Items
  // =============================================================================

  &__item {
    @include position(relative);
    @include size(calc(50% - #{$gs-gutter}), auto);
    @include padding(30px 0 30px 70px);
    @include margin(0 ($gs-gutter / 2) -1px);
    float: left;
    border-bottom: 1px solid tint(color(brand-black), 10%);
    border-top: 1px solid tint(color(brand-black), 10%);

    @include mq($until: tablet) {
      @include size(100%, auto);
      @include margin(null 0);
    }
  }


  // Icons
  // =============================================================================

  &__icon {
    display: block;
    @include position(absolute, 50% null null 0);
    transform-origin: 50%;
    transform: translate(0, -50%);

    &--area {
      @include size(40px, auto);
    }

    &--population {
      @include size(33px, auto);
    }

    &--exportation {
      @include size(41px, auto);
    }

    &--change {
      @include size(42px, auto);
    }

    &--language {
      @include size(42px, auto);
    }

    &--pib {
      @include size(41px, auto);
    }
  }


  // Values
  // =============================================================================

  &__value {
    display: block;
    @include font(lato, light, family weight);
    font-size: rem(38px);
    line-height: 1;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(brand-blue);
  }


  // Labels
  // =============================================================================

  &__label {
    display: block;
    @include margin(5px 0 0);
    @include font(lato, regular, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(100px);
    text-decoration: none;
    text-transform: none;
    color: rgba(#fff, 0.6);
  }
}
