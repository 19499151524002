/*------------------------------------*\
    FEED
\*------------------------------------*/

.feed {
  @include margin(0 0 -30px);

  @include mq($until: desktop) {
    @include margin(0);
  }


  // Box
  // =============================================================================

  &__box {
    @include position(relative);
    background-color: #fff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: color 0.2s $ease-out-quart, background-color 0.2s $ease-out-quart;

    svg {
      transition: fill 0.2s $ease-out-quart;
    }
  }


  // Square
  // =============================================================================

  &__shape {
    display: block;
    @include size(100%, auto);
  }


  // Author
  // =============================================================================

  &__author {
    display: inline-block;
    border-radius: 2px;
    @include padding(0 null);
    @include font(lato, bold, family weight);
    font-size: rem(14px);
    line-height: 20px;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    color: rgba(color(brand-black), 0.5);
    transition: color 0.2s $ease-out-quart;

    a,
    a:hover,
    a:active,
    a:focus {
      color: inherit;
      text-decoration: none;
    }

    .svg {
      float: left;
    }
  }


  // Icon
  // =============================================================================

  &__icon {
    float: right;
  }


  // Content
  // =============================================================================

  &__content {
    @include position(absolute, 0 null null 0);
    @include padding(30px);
    @include size(100%);
    @include font(lato, regular, family weight);
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    overflow: hidden;
    z-index: 1;

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }

    img {
      @include size(100%, auto);
      display: block;
      transition-duration: 0.6s;
      transition-timing-function: $ease-out-expo;
    }
  }


  // Metas
  // =============================================================================

  &__metas {
    @include position(absolute, null 30px 30px 30px);
    @include clearfix();
    z-index: 2;
  }


  // Subhead
  // =============================================================================

  &__subhead {
    @include font(merriweather, italic, family weight style);
    font-size: rem(13px);
    line-height: 2;
    color: #fff;
  }


  // Title
  // =============================================================================

  &__title {
    @include font(lato, black, family weight);
    font-size: rem(20px);
    line-height: 1.5;
    color: #fff;
  }


  // Push
  // =============================================================================

  &--push {
    @include margin(155px null null);

    @include mq($until: desktop) {
      @include margin(0);
    }
  }


  // Pull
  // =============================================================================

  &--pull {
    @include margin(-155px null null);

    @include mq($until: desktop) {
      @include margin(0);
    }
  }


  // Offset
  // =============================================================================

  &--offset {
    @include margin(null null null 155px);

    @include mq($until: desktop) {
      @include margin(0);
    }
  }


  // Instagram
  // =============================================================================

  &--instagram {
    .feed__content {
      @include padding(0);
    }

    .feed__author {
      @include padding(15px);
      background: rgba(color(brand-black), 0.3);
      color: #fff;

      .svg {
        @include margin(1px 10px null null);
      }

      svg {
        fill: #fff;
      }
    }

    &:hover {
      img {
        transform: scale(1.15);
        transition-duration: 12s;
        transition-timing-function: cubic-bezier(0.030, 1.000, 0.000, 0.850);
      }
    }
  }


  // Twitter
  // =============================================================================

  &--twitter {
    color: color(social-twitter);

    svg {
      fill: color(social-twitter);
    }

    &:hover {
      background-color: color(social-twitter);
      color: #fff;

      .feed__author {
        color: rgba(#fff, 0.5);
      }

      svg {
        fill: #fff;
      }
    }
  }


  // Facebook
  // =============================================================================

  &--facebook {
    color: color(social-facebook);

    svg {
      fill: color(social-facebook);
    }

    &:hover {
      background-color: color(social-facebook);
      color: #fff;

      .feed__author {
        color: rgba(#fff, 0.5);
      }

      svg {
        fill: #fff;
      }
    }
  }


  // Call To Action
  // =============================================================================

  &--call-to-action {
    background-color: color(brand-blue);
    text-align: center;

    .feed__content {
      @include position(absolute, 50% null null 50%);
      @include padding(0);
      @include size(100%, auto);
      transform: translate(-50%, -50%);
      overflow: visible;
    }

    .feed__icon {
      float: none;
      @include position(relative);
      @include size(140px);
      @include margin(0 auto 20px);

      &::before {
        @include position(absolute, 0 null null 0);
        @include size(140px);
        content: '';
        display: block;
        border-radius: 50%;
        border: 20px solid rgba(color(white), 0.2);
        transition: transform 0.2s $ease-out-quart
      }
    }

    .svg {
      @include position(absolute, 50% null null 50%);
      //margin-top: 5px;
      transform: translate(-50%, -50%);
    }

    svg {
      fill: #fff;
    }

    &:hover {
      .feed__icon {
        &::before {
          transform: scale(1.15);
        }
      }
    }
  }


  // Small
  // =============================================================================

  &--small {
    background-color: color(brand-red);

    .feed__icon {
      @include size(auto);
      @include margin(null null 10px);
      
      &::before {
        content: none;
      }

      .svg {
        @include position(relative, auto null null auto);
        transform: none;
      }
    }

    .feed__title {
      line-height: 1;
      font-weight: 400;
    }

    &:hover {
      background-color: tint(color(brand-red), 15%);
    }
  }
}

