/*------------------------------------*\
    PAGE - MEDIAS
\*------------------------------------*/

.medias {
  // grid
  // =============================================================================

  .grid {
    .member,
    .post,
    .call-to-action {
      @include margin(0 0 30px);

      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }
  }
}
