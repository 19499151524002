/*------------------------------------*\
    CHART
\*------------------------------------*/

.chart {
  // Canvas
  // =============================================================================

  &__canvas {
    @include size(100%, 335px);

    @include mq($until: desktop) {
      width: 100% !important;
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include margin(0 0 30px);
    @include font(lato, regular, family weight);
    font-size: rem(20px);
    line-height: 1.4;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(brand-black);
  }


  // Tables
  // =============================================================================

  .table {
    width: calc(100% - 40px);

    @include mq($until: desktop) {
      width: 100%;
      margin-bottom: 30px;
    }

    &__row {
      @include clearfix();
      border-bottom: 1px solid color(border);

      &:first-child {
        .table__value {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      &:last-child {
        border: 0 none;

        .table__value {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }

    &__label {
      float: left;
      @include size(60%, auto);
      @include padding(15px);
      @include font(lato, regular, family weight);
      font-size: rem(14px);
      line-height: 1;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      color: tint(color(text), 20%);
    }

    &__value {
      float: left;
      @include size(calc(40% - 10px), auto);
      @include padding(15px);
      @include margin(0 10px 0 0);
      background: tint(color(brand-white), 25%);
      @include font(lato, bold, family weight);
      font-size: rem(14px);
      line-height: 1;
      letter-spacing: 0;
      text-decoration: none;
      text-transform: none;
      text-align: center;
      color: color(brand-blue);

      &:first-child {
        border-radius: 5px;
      }
    }
  }
}
