/*------------------------------------*\
    CONTENT - OPINION
\*------------------------------------*/

.content-opinion {
  // Author
  // =============================================================================

  .author {
    @include padding(35px 0 0);
    border-top: 1px solid color(border);

    @include mq($until: desktop) {
      @include padding(30px 0 35px);
    }

    &__name {
      @include filet(color(border), 50px, 1px, bottom, 15px 0);
      @include font(lato, bold, family weight);
      font-size: rem(16px);
      line-height: 2;
      letter-spacing: ls(20px);
      text-transform: none;
      text-decoration: none;
      color: color(brand-black);
    }

    &__function {
      @include font(merriweather, italic, family weight style);
      font-size: rem(12px);
      line-height: 2;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      color: color(text);
      opacity: 0.75;
    }

    &__location {
      @include margin(10px 0 0);
      @include font(lato, black, family weight);
      font-size: rem(10px);
      line-height: 1;
      letter-spacing: ls(100px);
      text-transform: uppercase;
      text-decoration: none;
      color: color(brand-black);

      svg {
        float: left;
        fill: color(brand-black);
        @include margin(1px 10px 0 0);
      }
    }
  }


  // Text
  // =============================================================================

  .content__text {
    @include padding(35px 0 0);
    border-top: 1px solid color(border);

    @include mq($until: tablet) {
      @include padding(30px 0 0);
    }

    @for $i from 1 through $gs-count {
      $length: 100% / $i;

      &.col-#{$i} {
        @include border-pattern(color(border), $length, 100%, 1px, top);
        border-top: 0 none;

        @include mq($until: desktop) {
          border-top: 1px solid color(border);

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }

    &.col-1 {
      border-top: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
