/*------------------------------------*\
    PAGE - CASE DETAIL
\*------------------------------------*/

.case-detail {
  // Case
  // =============================================================================
  .case {
    &__content {
      padding-top: 55px;
    }
  }
}
