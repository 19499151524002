/*------------------------------------*\
    PAGE - AWEC MEMBER
\*------------------------------------*/

.awex-member {
  // Footer
  // =============================================================================

  .page-footer {
    background: color(brand-white);
    border: 0 none;

    .title {
      color: color(brand-black);

      span::before,
      span::after {
        background: color(brand-black);
      }
    }

    .post {
      @include mq($until: desktop) {
        @include margin(0 0 30px);
      }

      &__figure {
        display: none;
      }

      &__content {
        @include padding(null null 90px);
      }

      &:hover {
        background: color(brand-red);

        // Title
        .post__title {
          color: #fff;
        }

        // Subhead
        .post__subhead {
          color: rgba(#fff, 0.6);

          svg {
            fill: #fff;
          }
        }

        // Button
        .post__button {
          border-color: rgba(#fff, 0.4);

          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}
