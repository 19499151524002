/*------------------------------------*\
    CONTENT - LEAD
\*------------------------------------*/

.content-lead {
  // Title
  // =============================================================================

  .content__title {
    @include filet(color(brand-blue));
  }


  // Text
  // =============================================================================

  .content__text {
    @include padding(50px 0 0);
    @include border-pattern(color(border), 50%, 100%, 1px, top);
    @include font(merriweather, regular, family weight);
    font-size: rem(18px);
    line-height: 1.9;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(text);

    @include mq($until: tablet) {
      @include padding(30px 0 0);
      font-size: rem(16px);
      border-top: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
