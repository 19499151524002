/*------------------------------------*\
    PAGE - COUNTRY
\*------------------------------------*/

.country {
  // Sub Menu
  // =============================================================================

  .sub-menu {
    .menu__list {
      display: table;
    }
    .menu__item {
      display: table-cell;
    }

    @include mq($until: desktop) {
      .menu__list {
        display: block;
      }

      .menu__item {
        display: inline-block;
      }
    }
  }


  // Post
  // =============================================================================

  .post {
    &__figure {
      display: none;
    }
  }


  // grid
  // =============================================================================

  .grid {
    &__title {
      @include filet(color(brand-blue));
      border-bottom: 1px solid color(border);
    }

    .member,
    .post,
    .call-to-action {
      @include margin(0 0 30px);

      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }
  }
}

@import 'country-landing';
@import 'country-contacts';
@import 'country-content';
@import 'country-agenda';
