/*------------------------------------*\
    SEARCH
\*------------------------------------*/


.search-form {
  .form-field {
    @include position(relative);
    @include margin(0);
  }

  .form-control {
    @include size(100%, auto);
    @include padding(15px 60px 15px 0);
    @include font(lato, light, family weight);
    display: block;
    border: 0 none;
    border-bottom: 3px solid rgba(#fff, 0.2);
    background: transparent;
    font-size: rem(45px);
    line-height: 1;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: #fff;
    transition: border-bottom 0.2s $ease-out-quad;

    &::placeholder {
      transition: opacity 0.2s $ease-out-quad;
    }

    &:focus {
      border-bottom: 3px solid rgba(#fff, 1);

      &::placeholder {
        opacity: 0;
      }
    }

    @include mq($until: tablet) {
      font-size: rem(30px);
    }
  }

  .form-submit {
    @include position(absolute, 0 0 null null);
    @include margin(20px 0 0);
    fill: #fff;
    cursor: pointer;
    transition: fill 0.2s $ease-out-quad;

    &:hover {
      fill: color(brand-red);
    }

    @include mq($until: tablet) {
      margin-top: 10px;
    }
  }
}
