/*------------------------------------*\
    PAGE - 404
\*------------------------------------*/

.page-404 {
  // Header
  // =============================================================================

  .page-title {
    @include font(lato, regular);
    font-size: rem(45px);
    line-height: 1.1;
    color: #fff;
    margin-bottom: 100px;

    @include mq($until: desktop) {
      margin-bottom: 80px;
    }

    @include mq($until: tablet) {
      font-size: rem(35px);
      margin-bottom: 55px;
    }
  }

  .page-header {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url('../assets/images/404/background.jpg');
    background-color: color(brand-black);
    background-position: center top;
    background-size: cover;

    @include mq($until: desktop) {
      padding-top: 55px;
      padding-bottom: 55px;
      min-height: 0;
    }

    @include mq($until: tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .center {
      @include position(relative);
      z-index: 2;
    }
  }


  // Tags
  // =============================================================================

  .tags-list {
    @include margin(40px null null);
    @include padding(0 240px 0 0);

    @include mq($until: desktop) {
      @include padding(0);
    }
  }
}
