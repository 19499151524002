/*------------------------------------*\
    CONTENT - LIST
\*------------------------------------*/

.content-list {
  // Title
  // =============================================================================

  .content__title {
    @include filet(color(brand-blue));
  }


  // Text
  // =============================================================================

  .content__text {
    @include border-pattern(color(border), 33%, 100%, 1px, top);

    @include mq($until: desktop) {
      @include border-pattern(color(border), 50%, 100%, 1px, top);
    }

    @include mq($until: tablet) {
      border-top: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }
  }


  // Item
  // =============================================================================

  .content__item {
    @include position(relative);
    @include margin(0 -15px 0 -22px);
    @include padding(30px 0);
    font-size: 0;

    @include mq($until: desktop) {
      @include margin(0);
    }

    @include mq($until: tablet) {
      border-bottom: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }

    &::before,
    &::after {
      content: '';
      @include size(auto, 1px);
      background: color(border);
    }

    &::before {
      @include position(absolute, null calc(50% + 15px) 0 15px);
    }

    &::after {
      @include position(absolute, null 15px 0 calc(50% + 15px));
    }

    &:last-child {
      padding-bottom: 0;
      border: 0 none;

      &::before,
      &::after {
        display: none;
      }
    }

    .title,
    .text {
      display: inline-block;
      vertical-align: top;
      @include size(50%, auto);
      @include padding(0 15px);

      @include mq($until: tablet) {
        display: block;
        @include size(100%, auto);
      }
    }

    .title {
      @include mq($until: desktop) {
        @include padding(0 15px 0 0);
      }

      @include mq($until: tablet) {
        @include padding(0);
      }
    }

    .text {
      @include mq($until: desktop) {
        @include padding(0 0 0 15px);
      }

      @include mq($until: tablet) {
        @include padding(0);
      }
    }

    @include mq($until: desktop) {
      &::before {
        left: 0;
        right: calc(50% + 15px);
      }
      &::after {
        right: 0;
        left: calc(50% + 15px);
      }
    }
  }
}
