/*------------------------------------*\
    PAGE - CONTACT AEC
\*------------------------------------*/

.contact-aec {
  // Grid
  // =============================================================================

  .grid {
    &__title {
      @include padding(35px 0 0);
      @include filet(color(brand-blue));
      border-top: 1px solid color(border);
      border-bottom: 1px solid color(border);
    }

    .call-to-action {
      @include margin(0 0 30px);

      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }
  }


  // Member
  // =============================================================================

  .member {
    @include padding(35px 0 0);
    @include margin(0);
    background: transparent;
    border-top: 1px solid color(border);

    @include mq($until: tablet) {
      @include padding(30px 0 0);
    }

    // POSTAL
    &__postal {
      @include margin(50px 0 0);

      @include mq($until: tablet) {
        @include margin(30px 0 0);
      }
    }
  }


  // Content Lead
  // =============================================================================

  .content-lead {
    @include padding(80px 0);

    @include mq($until: desktop) {
      @include padding(55px 0);
    }

    @include mq($until: tablet) {
      @include padding(40px 0);
    }
  }
}
