/*------------------------------------*\
    PAGE - NEWS LISTING
\*------------------------------------*/

.news-list {
  // Grid
  // =============================================================================

  .grid {
    .post,
    .member {
      @include margin(0 0 30px);

      @include mq($until: tablet) {
        @include margin(0 0 15px);
      }
    }
  }
}
