/// Default typography settings, to be included as soon as possible in the HTML
/// 1. Make type rendering look crisper
/// 2. The default font size is 16px
///
/// @requires {variable} $base-font-size
html {
  @include font-smoothing; /* 1 */
  font-size: strip-units($base-font-size) / 16 * 100%; /* 2 */
}

body {
  @include font(lato, regular);
  font-size: rem($base-font-size);
  line-height: $base-line-height;
  color: tint(color(black), 30%);
}

/// Links
a {
  cursor: pointer;
  color: color(link);
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: shade(color(link), 40%);
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: color(heading);
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  //text-align: left;
  @include font(lato, regular, family weight);

  a,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
    text-decoration: none;
  }
}

h1 {
  font-size: rem(60px);
  font-weight: 300;
  line-height: 1.3;

  strong {
    font-weight: 400;
  }
}

h2 {
  @include margin(null null 15px);
  font-size: rem(38px);
  line-height: 1.2;
}

h3 {
  font-size: rem(25px);
  font-weight: 700;
  line-height: 1.3;

  @include mq($until: tablet) {
    font-size: rem(23px);
  }
}

h4 {
  font-size: rem(16px);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: ls(10px);
}

h5 {
  @include margin(null null 15px);
  font-size: rem(20px);
  line-height: 1.3;
}

h6 {
  @include margin(null null 15px);
  font-size: rem(16px);
  line-height: 1.6;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

/// Lead
.lead {
  @include margin(40px null);
  @include padding(40px null);
  @include font(merriweather, regular, family weight);
  @include border-pattern(color(border));
  font-size: rem(18px);
  line-height: 1.9;
  color: color(text);

  @include mq($until: tablet) {
    font-size: rem(16px);
  }
}

/// Simple Link
.simple-link {
  @include position(relative);
  @include padding(0 20px 0 0);
  @include font(lato, black, family weight);
  font-size: rem(10px);
  line-height: 1;
  letter-spacing: ls(100px);
  text-transform: uppercase;
  text-decoration: none;
  color: color(brand-blue);
  transition: color 0.2s $ease-out-quart;

  .svg {
    @include position(absolute, null 0 -1px null);
    display: inline-block;
    transform: translate(0, -50%);
    transition: transform 0.2s $ease-out-quad;

    svg {
      fill: color(brand-red);
    }
  }

  .icon-download {
    bottom: -5px;
  }

  &--reverse {
    @include padding(0 0 0 20px);

    .svg {
      left: 0;
      right: auto;
    }
  }

  &--white,
  &--black {
    &:hover,
    &:focus,
    &:active {
      color: color(brand-blue);
    }
  }

  &--white {
    color: #fff;
  }

  &--black {
    color: color(brand-black);
  }

  &:hover,
  &:focus,
  &:active {
    color: color(brand-red);
    text-decoration: none;

    .svg {
      transform: translate(4px, -50%);
    }
  }
}

.typeset {
  @for $i from 1 through $gs-count {
    @include mq($from: desktop) {
      &.col-#{$i} {
        @include size(100%, auto);
        column-count: $i;
        column-gap: $gs-gutter;
      }
    }

    @include mq($from: tablet, $until: desktop) {
      &.md-col-#{$i} {
        @include size(100%, auto);
        column-count: $i;
        column-gap: $gs-gutter;
      }
    }

    @include mq($until: tablet) {
      &.sm-col-#{$i} {
        @include size(100%, auto);
        column-count: $i;
        column-gap: $gs-gutter;
      }
    }
  }

  /// Images
  img {
    @include mq($until: tablet) {
      max-width: 100%;
      height: auto;
    }
  }

  /// Paragraphs
  /// 1. OpenType ligatures and oldstyle figures enabled if available.
  p {
    @include margin(0 0 20px);
    @include font(lato, regular, family weight);
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    text-align: left;
    color: color(text);

    &:last-child {
      @include margin(0);
    }
  }

  /// Links
  a:not(.button, .simple-link) {
    color: color(black);
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: color(text);
    }
  }

  /// Bold
  b,
  strong {
    @include font(lato, bold, weight);
    color: color(black);
  }

  /// Italic
  i,
  em {
    @include font(lato, italic, style);
  }

  /// Caption and inline small text
  small,
  .caption {
    @include font(lato, regular);
    font-size: rem(ms(-2));
  }

  small {
    line-height: 1rem;
  }

  .caption {
    @include baseline(lato, regular, ms(-1), 2, 2);
    color: tint(color(heading), 80%);
  }

  // Nice spacing for captions.
  h1 + .caption,
  h2 + .caption,
  h3 + .caption {
    margin-top: -1rem;
  }

  h4 + .caption,
  h5 + .caption,
  h6 + .caption {
    margin-top: 0;
  }

  /// Lists
  ul {
    @include baseline(lato, regular, $base-font-size, 2, 2);
    @include margin(null null 30px);
    color: color(text);

    &:first-child {
      @include padding(0 null null);
    }

    &:last-child {
      @include padding(null null 0);
      @include margin(null null 0);
    }

    li {
      @include position(relative);
      @include padding(null null null 25px);
      @include margin(null null 10px);

      &::before {
        content: '•';
        @include position(absolute, 0 null null 0);
        font-weight: 700;
        color: color(black);
      }
    }
  }


  ol {
    @include font(lato, bold, family weight);
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(brand-black);
    counter-reset: my-counter;

    li {
      @include position(relative);
      @include padding(null null 30px 25px);
      @include margin(null null 30px);
      border-bottom: 1px solid color(border);
      counter-increment: my-counter;

      &::before {
        content: counter(my-counter);
        @include position(absolute, 0 null null 0);
        font-size: rem(16px);
        font-weight: 700;
        color: color(black);
      }

      &:last-child {
        @include margin(null null 0);
        @include padding(null null 0);
        border: 0 none;
      }
    }
  }

  /// Quotes
  blockquote {
    border-left: rem(2) solid color(link);
    padding-left: 1rem;

    p {
      @include font(merriweather, italic);

      // Add spacing below blockquote paragraphs to align to baseline grid.
      $font-size: strip-units(ms(0));
      $root-size: strip-units($base-font-size);
      $font-properties: map-deep-get($base-font-stack, merriweather, italic);
      $cap-height: if($font-properties, map-get($font-properties, cap-height), 0.68);
      $line-height: 2;
      $below: 3;

      font-size: #{$font-size / $root-size}rem;
      line-height: #{$line-height}rem;

      $baseline-shift: #{($font-size / 2 * (($line-height * $root-size / $font-size) - $cap-height)) / $root-size + 0.00001};
      $baseline-push: #{$below - (($font-size * (($line-height * $root-size / $font-size) - $cap-height)) / $root-size + 0.00001)};

      margin-bottom: #{$baseline-push}rem;
      padding-bottom: #{$baseline-shift}rem;

      + cite {
        margin-top: -3rem;
      }
    }

    cite {
      @include font(lato, bold, family weight);
      @include baseline(lato, bold, ms(-2), 3, 3);
      display: block;
      text-transform: uppercase;
    }
  }
}

/// Horizontal rule
hr {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, color(border) 50%);
  background-position: 0 50%;
  background-repeat: repeat-x;
  background-size: 100% 0.15rem;
  border: 0;
  margin: 0;
  padding-bottom: 50px;
  padding-top: 40px;
}

/// Table
table {
  width: 100%;
  border-collapse: collapse;
  @include margin(50px null);

  &:last-child {
    @include margin(null null 0);
  }
}

tr {
  border-bottom: 1px solid color(border);

  &:last-child {
    border: 0 none;
  }
}

th, td {
  @include padding(15px 20px);
  text-align: left;
}

th {
  @include font(lato, black, family weight);
  font-size: rem(10px);
  line-height: 1.5;
  letter-spacing: ls(150);
  text-transform: uppercase;
  color: color(heading);
}
