// -----------------------------------------------------------------------------
// Very basic styles.
// -----------------------------------------------------------------------------


/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body {
  height: 100%;
}

body {
  @include size(100%);

  &.is-menu-open,
  &.is-popup-open,
  &.no-scroll {
    overflow: hidden;
  }
}

.container {
  @include size(100%);
}

.center {
  max-width: $gs-container;
  margin: 0 auto;

  @include mq($until: desktop) {
    @include padding(0 30px);
  }

  @include mq($until: tablet) {
    @include padding(0 15px);
  }
}

.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

*[data-href] {
  cursor: pointer;
}
