/*------------------------------------*\
    CONTENT - DESCRIPTION
\*------------------------------------*/

.content-description {
  @include font(lato, light, family weight);
  font-size: rem(25px);
  line-height: 1.7;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  color: color(brand-black);

  @include mq($until: tablet) {
    font-size: rem(20px);
  }
}
