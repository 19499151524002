/*------------------------------------*\
    MIXINS
\*------------------------------------*/

@mixin aspect-ratio($width, $height) {
  $width: strip-units($width);
  $height: strip-units($height);
  $ratio: $height / $width * 100%;
  position: relative;

  &:before {
    @include padding($ratio null null);
    display: block;
    content: '';
  }
}

@mixin border-pattern($color, $length: 50%, $width: 100%, $height: 1px, $position: top bottom) {
  // Define selector mapping
  // Map position to selector
  $selector: '';
  $map: (
    'top': '&::before',
    'bottom': '&::after'
  );

  // Rewrite variables
  // Ensure correct units
  $length: strip-units($length) + %;

  // Write gradient positions
  $color_start: 0%;
  $color_end  : calc(#{$length} - (#{$gs-gutter} / 2));
  $gap_start  : calc(#{$length} - (#{$gs-gutter} / 2));
  $gap_end    : calc(#{$length} + (#{$gs-gutter} / 2));

  // Check positions
  @if type-of($position) != list and
      type-of($position) != string {
    @warn 'Invalid type of position: "' + type-of($position) + '"';
  }

  // Loop on positions
  // Create the complete selector
  @for $i from 1 through length($position) {
    $getter: map-get($map, nth($position, $i));

    @if $getter == null {
      @warn 'This position doesn\'t exist: "' + $position + '"';
    }

    @if $selector != '' {
      $selector: $selector + ',' + $getter;
    } @else {
      $selector: $getter;
    }
  }

  // Apply style to create pattern
  // Use of linear-gradient
  @include position(relative);

  #{$selector} {
    content: '';
    display: block;
    @include size($width, $height);
    background: $color;
    background: repeating-linear-gradient(
      to left,
      $color $color_start,
      $color $color_end,
      transparent $gap_start,
      transparent $gap_end
    );
  }

  &::before { @include position(absolute, 0 null null 0); }
  &::after { @include position(absolute, null null 0 0); }
}

@mixin border-enclosure($color, $width: 1000px, $height: 1px, $gap: 30px) {
  overflow: hidden;
  text-align: center;

  > * {
    display: inline-block;
    @include position(relative);
    @include padding(null $gap);

    &::before,
    &::after {
      content: '';
      display: block;
      @include size($width, $height);
      background-color: $color;
      opacity: 0.1;
    }

    &::before {
      @include position(absolute, 50% 100% null null);
    }

    &::after {
      @include position(absolute, 50% null null 100%);
    }
  }

  @include mq($until: tablet) {
    > * {
      @include padding(0);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

@mixin filet($color, $width: 50px, $height: 5px, $position: bottom, $margin: 35px null -1px) {
  // Define selector mapping
  // Map position to selector
  $map: (
    'top': '&::before',
    'bottom': '&::after'
  );

  // Check positions && margin
  @if type-of($position) != string {
    @warn 'Invalid type of position: "' + type-of($position) + '"';
  }

  @if type-of($margin) != list and
      type-of($margin) != string {
    @warn 'Invalid type of margin: "' + type-of($margin) + '"';
  }

  // Create the selector
  $selector: map-get($map, $position);

  #{$selector} {
    content: '';
    display: block;
    @include size($width, $height);
    @include margin($margin);
    background-color: $color;
  }
}
