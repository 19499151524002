/*------------------------------------*\
    MEMBER
\*------------------------------------*/

.member {
  @include padding(45px);
  @include font(lato, regular, family weight);
  background: #fff;
  min-height: 335px;
  font-size: rem(14px);
  line-height: 2;
  letter-spacing: ls(20px);
  text-transform: none;
  text-decoration: none;
  color: color(brand-black);
  box-shadow: 0 2px 10px rgba(color(brand-black), 0);
  transition: box-shadow 0.8s $ease-out-quart, transform 0.8s $ease-out-quart;

  @include mq($until: tablet) {
    min-height: 0;
    @include padding(30px);
  }

  &[data-href]:hover {
    transform: translate(0, -8px);
    box-shadow: 0 2px 10px rgba(color(brand-black), 0.05);

    .member__name,
    .simple-link {
      color: color(brand-red);
    }

    .simple-link {
      .svg {
        transform: translate(4px, -50%);
      }
    }
  }


  // Name
  // =============================================================================

  &__name {
    @include filet(color(border), 50px, 1px, bottom, 10px 0 15px);
    @include font(lato, regular, family weight);
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(brand-black);
    transition: color 0.2s $ease-out-quart;

    .firstname {
      font-weight: 700;
    }

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }


  // Function
  // =============================================================================

  &__function {
    min-height: 60px;
    @include font(merriweather, italic, family weight style);
    font-size: rem(12px);
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: tint(color(text), 40%);

    @include mq($until: tablet) {
      min-height: 0;
      margin-bottom: 30px;
    }
  }


  // Location
  // =============================================================================

  &__location {
    @include padding(0 0 0 20px);
    @include margin(0 0 30px);

    .svg {
      float: left;
      @include margin(0 10px 0 -20px);

      svg {
        fill: color(brand-black);
      }
    }

    .label,
    .city {
      display: block;
    }

    .city {
      @include margin(5px 0 0);
      @include font(lato, black, family weight);
      font-size: rem(10px);
      line-height: 1;
      letter-spacing: ls(100px);
      text-transform: uppercase;
      text-decoration: none;
      color: color(brand-black);
    }
  }


  // Tel/Fax
  // =============================================================================

  &__tel,
  &__fax {
    strong {
      color: color(brand-blue);
    }
  }

  &__tel {
    @include margin(5px 0 0);
  }

  &__fax {
    @include margin(0 0 5px);
  }


  // Email/Website
  // =============================================================================

  &__email,
  &__website {
    a {
      color: color(brand-black);
      font-weight: 700;
      text-decoration: none;
      transition: color 0.2s $ease-out-quart;

      &:hover,
      &:focus,
      &:active {
        color: color(brand-blue);
      }
    }
  }


  // Skills
  // =============================================================================

  &__skills {
    @include padding(0 0 0 20px);
    @include margin(25px 0 0);

    .svg {
      float: left;
      @include margin(0 10px 0 -20px);

      svg {
        fill: color(brand-black);
      }
    }

    .list {
      @include margin(10px 0 0 2px);
      @include clearfix();

      &__item {
        float: left;
        @include margin(0 0 10px);
        @include font(lato, black, family weight);
        font-size: rem(10px);
        line-height: 1;
        letter-spacing: ls(100px);
        text-transform: uppercase;
        text-decoration: none;
        color: tint(color(text), 40%);

        &::after {
          content: '-';
          display: inline-block;
          @include margin(0 3px);
        }

        &:last-child::after {
          display: none;
        }
      }

      &__link {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        transition: color 0.2s $ease-out-quart;

        &:hover,
        &:focus,
        &:active {
          color: color(brand-blue);
          text-decoration: none;
        }
      }
    }
  }


  // Social
  // =============================================================================

  &__social {
    @include margin(10px -5px 0);

    li {
      display: inline-block;
      @include margin(0 5px);
    }

    svg {
      transition: fill 0.2s $ease-out-quart;
    }

    a:hover,
    a:active,
    a:focus {
      svg {
        fill: color(brand-blue);
      }
    }
  }


  // Office & Postal
  // =============================================================================

  &__office,
  &__postal {
    @include font(lato, regular, family weight);
    font-size: rem(14px);
    line-height: 2;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;

    strong {
      color: color(brand-blue);
    }

    .title {
      @include margin(0 0 25px);
      @include font(lato, bold, family weight);
      font-size: rem(16px);
      line-height: 1.6;
      letter-spacing: 0;
      text-transform: none;
      text-decoration: none;
      color: color(brand-black);
    }

    .s_tel {
      @include margin(5px 0 0);
    }
    .s_fax {
      @include margin(0 0 5px);
    }

    .email a,
    .website a {
      font-weight: 700;
      color: color(brand-black);
      transition: color 0.2s $ease-out-quart;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: color(brand-blue);
      }
    }
  }


  // Detail
  // =============================================================================

  &__detail {
    @include position(relative);
    @include padding(35px 0 35px 80px);
    @include border-pattern(color(border), 50%, 100%, 1px, top);

    &:last-child {
      padding-bottom: 0;
    }

    @include mq($until: tablet) {
      border-top: 1px solid color(border);

      &::before,
      &::after {
        display: none;
      }
    }

    .svg {
      @include position(absolute, 40px null null 15px);

      svg {
        fill: color(brand-blue);
      }
    }

    .label {
      @include font(lato, bold, family weight);
      color: color(black);
    }

    .city {
      @include font(lato, black, family weight);
      font-size: rem(10px);
      line-height: 3;
      letter-spacing: ls(100px);
      text-transform: uppercase;
      text-decoration: none;
      color: color(brand-black);
    }

    .tags-list {
      @include margin(15px null null);
    }

    .tag {
      @include font(lato, black, family weight);
      font-size: rem(10px);
      letter-spacing: ls(100px);
      text-transform: uppercase;
    }
  }


  // Simple Link
  // =============================================================================

  .simple-link {
    display: inline-block;
    @include filet(color(border), 50px, 1px, top, 25px 0);
  }
}
