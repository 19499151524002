/*------------------------------------*\
    NEXT LINK
\*------------------------------------*/

.next-link {
  @include position(relative);
  @include size(calc(50% + 135px), auto);
  @include padding(60px 0 55px);
  @include margin(0 0 -50px);
  background: color(brand-red);
  text-align: right;
  z-index: 1;

  &::after {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background: shade(color(brand-red), 15%);
    transform-origin: left 50%;
    transform: scale(0, 1);
    transition: transform 0.7s $ease-out-quart;
    z-index: -1;
  }

  &:hover {
    &::after {
      transform: scale(1, 1);
    }
  }

  @include mq($until: desktop) {
    @include size(100%, auto);
    @include margin(0);
    text-align: center;
  }


  // Center
  // =============================================================================

  .center {
    max-width: 600px;
    margin-right: 0;
    padding-right: 110px;
    text-align: left;

    @include mq($until: desktop) {
      text-align: center;
      max-width: 100%;
      padding-right: 0;
    }
  }


  // Subhead
  // =============================================================================

  &__subhead {
    @include margin(0 0 15px);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1;
    letter-spacing: ls(100px);
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(#fff, 0.5);
  }


  // Title
  // =============================================================================

  &__title {
    color: #fff;

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }


  // Button
  // =============================================================================

  &__button {
    @include position(absolute, 50% 50px null null);
    @include margin(0);
    transform-origin: 50%;
    transform: translate(0, -50%);

    svg {
      @include position(absolute, 0 null null 0);
      @include size(100%);
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        fill: #fff;
      }
    }

    @include mq($until: tablet) {
      display: none;
    }
  }
}
