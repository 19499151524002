/*------------------------------------*\
    CALL TO ACTION
\*------------------------------------*/

.call-to-action {
  @include padding(45px);
  background: color(brand-black);
  transition: background 0.2s $ease-out-quart;

  @include mq($until: tablet) {
    @include padding(30px);
  }

  &[data-href]:hover {
    .simple-link {
      color: color(brand-blue);
      
      .svg {
        transform: translate(4px, -50%);
      }
    }

    .button {
      background-color: tint(color(brand-red), 15%);
    }
  }

  // Title
  // =============================================================================

  &__title {
    @include margin(0);
    @include font(lato, regular, family weight);
    font-size: rem(20px);
    line-height: 1.4;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    color: #fff;
    transition: color 0.2s $ease-out-quart;

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }


  // Text
  // =============================================================================

  &__text {
    @include margin(20px 0 0);
    min-height: 170px;

    p, ul, ol, li {
      color: rgba(#fff, 0.6);
    }

    @include mq($until: tablet) {
      min-height: 0;
    }
  }


  // Simple Link / Button
  // =============================================================================

  .simple-link {
    display: inline-block;
    @include filet(rgba(#fff, 0.15), 50px, 1px, top, 25px 0);
  }

  .button {
    @include margin(25px 0 0);
  }
}
