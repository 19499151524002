/*------------------------------------*\
    THEME - PRINT
\*------------------------------------*/

@media print {
  /// Remove basic space
  /// around the content
  @page {
    margin: 0;
  }

  /// Body
  body {
    /// Add space around the content
    /// Use cm for print
    padding: 1.5cm;
  }

  /// Remove useless UI
  /// components (header, footer, ...)
  .site-header,
  .site-footer,
  .page-footer,
  .breadcrumb,
  .sub-menu,
  .sharing,
  .content-media,
  .content-aside,
  .content-case {
    display: none;
  }

  /// Color page header
  .page-header {
    padding: 0;

    .page-header__title {
      margin: 0;
      font-size: 18pt;
      color: black;
    }
    .page-header__subtitle {
      font-size: 15pt;
      color: #CCC;
    }
  }

  /// Normalize content padding
  /// by using cm for print
  .content {
    padding-top: 1cm;
    padding-bottom: 1cm;

    .content__title {
      font-size: 15pt;
      padding-bottom: 0.75cm;

      &::after {
        display: none;
      }
    }

    .content__text {
      font-size: 10pt;
    }
  }

  .content-description {
    .content__text {
      font-size: 13pt;
    }
  }

  .content-lead {
    .content__text {
      font-size: 13pt;
      color: #CCC;
    }
  }

  .content-media {
    & + .content {
      padding-top: 1cm;
      padding-bottom: 1cm;
    }
  }

  .content-lead {
    .content__text {
      border-top: 1px solid #CCC;
    }
  }

  .content-quote {
    .content__title {
      font-size: 8pt;
      padding-bottom: 0.2cm;
    }

    &.red,
    &.blue {
      .content__text {
        color: black;
      }
    }
  }

  .content-opinion {
    .author__name {
      &::after {
        display: none;
      }
    }

    .author__function {
      color: #CCC;
    }
  }

  /// Typeset
  .typeset {
    font-size: 10pt;
  }

  /// Stats
  .stats {
    padding-top: 0;
    padding-bottom: 0;

    .stats__title,
    .stats__label {
      color: black;
    }

    .stats__title {
      font-size: 15pt;
      padding-bottom: 0.75cm;

      &::after {
        display: none;
      }
    }

    .stats__value {
      font-size: 20pt;
      color: #548fca;
    }

    .stats__label {
      font-size: 10pt;
      color: #CCC;
    }

    .stats__item {
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid #CCC;
      border-bottom: 1px solid #CCC;
    }

    .stats__icon {
      display: none;
    }
  }

  /// Charts
  .chart {
    .chart__title {
      font-size: 15pt;
      margin-bottom: 0.75cm;
    }

    .table__row {
      border-bottom: 1px solid #CCC;
    }

    .table__label,
    .table__value {
      font-size: 10pt;
      width: 50%;
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      background: transparent;
    }

    .table__value {
      text-align: right;
    }
  }

  /// Country Landing
  .country-landing {
    .content,
    .chart {
      page-break-before: always;
      page-break-after: always;
    }

    .chart {
      padding-top: 1.5cm;

      &:first-child {
        padding-top: 0;
      }
    }

    .page-header,
    .call-to-action {
      display: none;
    }

    .page-footer {
      .title {
        font-size: 15pt;
        color: black;
        padding-bottom: 0.75cm;
        border-bottom: 1px solid #CCC;

        &::after {
          display: none;
        }
      }
      .map {
        height: 500px;
      }
    }

    .content-data {
      padding-top: 1.5cm;
    }

    .content-lead {
      padding-top: 0;
    }
  }
}
