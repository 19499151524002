/*------------------------------------*\
    PAGE - HOME
\*------------------------------------*/

.home {
  // Header
  // =============================================================================

  .page-header {
    min-height: 710px;
    padding-bottom: 250px;
    background-color: color(brand-black);
    background-position: center top;
    background-size: cover;

    @include mq($until: desktop) {
      padding-bottom: 55px;
      min-height: 0;
    }

    @include mq($until: tablet) {
      padding-bottom: 40px;
    }

    &::before {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: #000;
      opacity: 0.45;
      z-index: 0;
    }

    &::after {
      content: '';
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: linear-gradient(45deg, color(brand-red) 0%, transparent 60%, transparent 80%, color(brand-red) 115%);
      z-index: 1;
    }

    .center {
      @include position(relative);
      z-index: 2;
    }
  }


  // Tags
  // =============================================================================

  .tags-list {
    @include margin(40px null null);
    @include padding(0 240px 0 0);

    @include mq($until: desktop) {
      @include padding(0);
    }
  }


  // News
  // =============================================================================

  .content-news {
    @include position(relative);
    z-index: 5;

    .carousel {
      @include margin(-275px 0 0);

      button {
        border-color: rgba(#fff, 0.2);

        svg {
          fill: #fff;
        }

        &:hover,
        &:focus,
        &:active {
          border-color: rgba(#fff, 0.5);
        }
      }

      @include mq($until: desktop) {
        @include margin(30px 0 0);

        button {
          border-color: rgba(color(brand-black), 0.2);

          svg {
            fill: color(brand-red);
          }

          &:hover,
          &:focus,
          &:active {
            border-color: rgba(color(brand-black), 0.5);
          }
        }
      }
    }
  }


  // About
  // =============================================================================

  .content-about {
    @include position(relative);
    @include padding(110px 0);

    @include mq($until: desktop) {
      @include padding(25px 0 55px);
    }

    @include mq($until: tablet) {
      @include padding(0 0 55px);
    }

    &::before {
      content: '';
      @include position(absolute, 0 0 null null);
      @include size(calc(50% - 175px), 100%);
      background: #fff;

      @include mq($until: desktop) {
        display: none;
      }
    }

    &__left {
      .title {
        @include margin(0 0 40px);
        font-size: rem(45px);

        @include mq($until: desktop) {
          font-size: rem(38px);
        }

        @include mq($until: tablet) {
          @include margin(0 0 30px);
          font-size: rem(25px);
        }
      }

      .typeset {
        @include position(relative);
        @include padding(0 0 0 80px);

        p {
          @include font(merriweather, regular, family weight);
          font-size: rem(16px);
        }

        &::before {
          content: '';
          @include position(absolute, 10px null null -90px);
          @include size(130px, 2px);
          background: color(brand-red);
        }

        @include mq($until: desktop) {
          @include padding(0);

          &::before {
            display: none;
          }
        }

        @include mq($until: tablet) {
          p {
            font-size: rem(14px);
          }
        }
      }
    }

    &__right {
      @include margin(0 0 0 $gs-column);
      @include padding(0 0 0 65px);

      .title {
        @include margin(0 0 30px);
      }

      @include mq($until: desktop) {
        @include padding(45px);
        @include margin(30px 0 0);
        background: #fff;
      }

      @include mq($until: tablet) {
        @include padding(30px);
      }
    }
  }


  // Data
  // =============================================================================

  .content-data {
    @include position(relative);
    @include padding(0);
    background: color(brand-white);

    .stats {
      max-width: 400px;
      padding-right: 65px;

      @include mq($until: desktop) {
        max-width: 100%;
        padding-right: 45px;
        padding-left: 45px;
      }

      @include mq($until: tablet) {
        padding-right: 30px;
        padding-left: 30px;
      }

      &__title {
        &::after {
          visibility: hidden;
        }
      }

      &__list {
        @include margin(null 0);
      }

      &__item {
        @include size(100%, auto);
        @include margin(null 0);
      }
    }

    &__left {
      padding-right: 0;
      padding-left: 0;
      margin-left: 15px;
      margin-right: -15px;

      @include mq($until: desktop) {
        margin-left: 0;
        margin-right: 0;
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    &__right {
      @include padding(80px 0 80px 60px);

      .title {
        @include margin(0 0 30px);
      }

      @include mq($until: desktop) {
        @include padding(55px 15px 40px);
      }
    }
  }


  // Actions
  // =============================================================================

  .content-actions {
    @include padding(75px 0);

    @include mq($until: desktop) {
      @include padding(0 0 55px);
    }
  }


  // Feed
  // =============================================================================

  .content-feed {
    @include padding(0);
  }


  // Case
  // =============================================================================

  .content-case {
    .case-controls {
      @include mq($until: tablet) {
        @include padding(10px null null);
      }
    }
  }
}
