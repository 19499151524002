/*------------------------------------*\
    PAGE - CONTACT LANDING
\*------------------------------------*/

.contact-landing {
  // Post
  // =============================================================================

  .post {
    &__figure {
      display: none;
    }

    &__content {
      @include padding(null null 90px);
    }
  }
}
