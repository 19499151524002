/*------------------------------------*\
    POST
\*------------------------------------*/

.post {
  @include position(relative);
  background: #fff;
  box-shadow: 0 2px 10px rgba(color(brand-black), 0);
  transition: background 0.2s $ease-out-quart, box-shadow 0.8s $ease-out-quart, transform 0.8s $ease-out-quart;

  &[data-href]:hover {
    transform: translate(0, -8px);
    box-shadow: 0 2px 10px rgba(color(brand-black), 0.05);

    .post__title,
    .simple-link {
      color: color(brand-red);
    }

    .simple-link {
      .svg {
        transform: translate(4px, -50%);
      }
    }
  }


  // Figure
  // =============================================================================

  &__figure {
    @include size(100%, 220px);
    @include margin(0);
    background-color: color(brand-black);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }


  // Content
  // =============================================================================

  &__content {
    //@include position(relative);
    @include padding(45px);
    min-height: 311px;

    @include mq($until: tablet) {
      @include padding(30px);
    }

    > .svg {
      @include position(absolute, null 40px 55px null);

      svg {
        fill: color(brand-red);
      }
    }
  }


  // Header
  // =============================================================================

  &__header {
    min-height: 50px;

    @include mq($until: tablet) {
      min-height: 0;
    }
  }


  // Footer
  // =============================================================================

  &__footer {
    @include margin(50px 0 0);
  }


  // Text
  // =============================================================================

  &__text {
    @include margin(20px 0 -25px);
    @include filet(color(border), 50px, 1px, top, 0 0 20px);
  }


  // Subhead
  // =============================================================================

  &__subhead {
    @include position(relative);
    @include margin(0 0 30px);
    @include padding(0 0 0 20px);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1.1;
    letter-spacing: ls(100px);
    text-transform: uppercase;
    text-decoration: none;
    color: tint(color(text), 40%);
    transition: color 0.2s $ease-out-quart;

     a {
       color: tint(color(text), 40%);
     }

    .svg {
      @include position(absolute, 1px null null 0);
      @include margin(0 10px 0 0);
    }

    svg {
      fill: color(brand-red);
      transition: fill 0.2s $ease-out-quart;
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include margin(0);
    @include font(lato, bold, family weight);
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: 0;
    word-break: break-word;
    color: color(brand-black);
    transition: color 0.2s $ease-out-quart;
    max-height: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    display: inline-block;

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }


  // Subtitle
  // =============================================================================

  &__subtitle,
  &__author .function {
    @include margin(5px 0 0);
    @include font(merriweather, italic, family weight style);
    font-size: rem(12px);
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: tint(color(text), 40%);
  }


  // Date
  // =============================================================================

  &__date {
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1.5;
    letter-spacing: ls(100px);
    text-transform: uppercase;
    text-decoration: none;
    color: tint(color(text), 40%);
  }


  // Location
  // =============================================================================

  &__location,
  &__author .name {
    @include margin(5px 0 0);
    @include font(lato, black, family weight);
    font-size: rem(10px);
    line-height: 1.5;
    letter-spacing: ls(100px);
    text-transform: uppercase;
    text-decoration: none;
    color: color(brand-black);
    max-height: 30px;

    svg {
      float: left;
      fill: color(brand-black);
      @include margin(2px 10px 0 0);
    }
  }


  // Author
  // =============================================================================

  &__author {
    @include margin(10px 0 0);

    .name,
    .function {
      display: block;
      @include margin(0);
    }

    .function {
      @include margin(3px 0 0);
    }
  }


  // Button
  // =============================================================================

  &__button {
    display: block;
    @include position(absolute, null null 45px 45px);
    @include margin(50px 0 0);
    @include size(45px);
    border: 2px solid rgba(#000, 0.1);
    border-radius: 50%;
    transition: border 0.2s $ease-out-quart;

    @include mq($until: tablet) {
      bottom: 30px;
      left: 30px;
    }

    .svg {
      @include position(absolute, 50% null null 50%);
      transform: translate(-50%, -50%);

      svg {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        fill: color(brand-red);
        transition: fill 0.2s $ease-out-quart;
      }
    }
  }


  // Simple Link
  // =============================================================================

  .simple-link {
    display: inline-block;
    @include filet(color(border), 50px, 1px, top, 0 0 25px);
  }
}
