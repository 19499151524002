/*------------------------------------*\
    PAGINATION
\*------------------------------------*/

.pagination {
  font-size: 0;
  margin-top: 70px;
  text-align: center;

  /// List
  ul {
    @include clearfix();
  }

  /// Items
  li {
    @include font(lato, bold, family weight);
    font-size: rem(16px);
    line-height: 1;
    letter-spacing: 0;

    &.prev {
      float: left;

      .svg {
        float: left;
        margin-right: 10px;
      }
    }
    &.next {
      float: right;

      .svg {
        float: right;
        margin-left: 10px;
      }
    }
  }


  /// Links
  a {
    color: color(brand-blue);
    text-decoration: none;
    transition: color 0.2s $ease-out-quart;

    svg {
      fill: color(brand-blue);
      transition: fill 0.2s $ease-out-quart;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      color: color(brand-red);

      svg {
        fill: color(brand-red);
      }
    }
  }
}
