/*------------------------------------*\
    PAGE - REGION DETAIL
\*------------------------------------*/

.region-detail {
  // Header
  // =============================================================================

  .page-header {
    &__title {
      @include margin(-50px 0 null);

      @include mq($until: desktop) {
        @include margin(0 0 null);
      }
    }
  }


  // Grid
  // =============================================================================

  .grid {
    &__title {
      @include padding(35px 0 0);
      @include filet(color(brand-blue));
      border-top: 1px solid color(border);
      border-bottom: 1px solid color(border);
    }
  }


  // Map
  // =============================================================================

  .map {
    display: block;
    @include position(relative);
    @include size(100%, 415px);
    @include margin(-135px 0 50px);
    background: color(brand-black);
    z-index: 2;

    @include mq($until: tablet) {
      @include size(100%, 290px);
      @include margin(0 null null);
    }
  }


  // Member/Post/Call To Action
  // =============================================================================

  .member,
  .post,
  .call-to-action {
    @include margin(0 0 30px);

    @include mq($until: tablet) {
      @include margin(0 0 15px);
    }
  }


  // Post
  // =============================================================================

  .post {
    &__header {
      min-height: 100px;
    }
  }


  // News
  // =============================================================================

  .content-news {
    background: color(brand-black-faded);
    background: linear-gradient(45deg, rgba(color(brand-black-faded), 1) 75%, color(brand-red) 120%);

    .title {
      @include margin(0 0 70px);
      @include border-enclosure(#fff);
      font-weight: 400;
      color: #fff;

      @include mq($until: desktop) {
        @include margin(0 0 55px);
      }

      @include mq($until: tablet) {
        @include margin(0 0 40px);
      }
    }
  }


  // Feed
  // =============================================================================

  .content-feed {
    @include padding(0);
  }
}
