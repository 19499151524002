/*------------------------------------*\
    VIDEO
\*------------------------------------*/

.video {
  @include position(relative);
  @include size(100%, 523px);
  background: color(brand-black);

  @include mq($until: desktop) {
    @include size(100%, 450px);
  }

  @include mq($until: tablet) {
    @include size(100%, auto);
  }


  // Cover
  // =============================================================================

  &__cover {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &.is-hidden {
      display: none;
    }

    &:hover {
      .video__play {
        background: color(brand-red);

        &::before {
          border-left-color: #fff;
        }
      }
    }
  }


  // Play
  // =============================================================================

  &__play {
    @include position(absolute, 50% null null 50%);
    @include size(80px);
    @include padding(0);
    @include margin(-40px 0 0 -40px);
    border: 0 none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
    transition: background 0.2s $ease-out-quad;

    &::before {
      content: '';
      @include position(absolute, 50% null null 50%);
      @include margin(0 0 0 2px);
      border-left: 14px solid color(brand-red);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      transform: translate(-50%, -50%);
      transition: border 0.2s $ease-out-quad;
    }
  }


  // Iframe
  // =============================================================================

  iframe {
    @include position(relative);
    @include size(100%);
    z-index: 0;
  }
}
