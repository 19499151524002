/*------------------------------------*\
    GRID SYSTEM
\*------------------------------------*/

.grid {
  @include margin(null null -30px);

  & + * {
    @include margin(60px 0 0);
  }


  // Columns
  // =============================================================================

  .col {
    transition: opacity 0.8s $ease-out-quad, transform 0.8s $ease-out-quad;

    &.is-hidden {
      opacity: 0;
      transform: translate(0, 30px);
    }

    &.is-visible {
      opacity: 1;
      transform: translate(0, 0);
    }
  }


  // Header
  // =============================================================================

  &__header {
    @include padding(35px 0 0);
    border-top: 1px solid color(border);

    @include mq($until: desktop) {
      @include margin(0 0 30px);
    }

    .filters{
      @include margin(40px 0 0);

      @include mq($until: tablet) {
        @include margin(30px 0 0);
      }
    }

    .simple-link {
      svg {
        bottom: -2px;
      }
    }
  }


  // Title
  // =============================================================================

  &__title {
    @include margin(0 0 20px);
    @include font(lato, bold, family weight);
    font-size: rem(25px);
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    text-decoration: none;
    color: color(brand-black);

    @include mq($until: tablet) {
      font-size: rem(23px);
    }
  }

  // Content
  // =============================================================================

  .content {
    padding: 35px 0 0;
    background: none;
    border-top: 1px solid color(border);

    .col {
      width: 100%;
    }

    .content__title {
      padding: 0;
      border: 0 none;
    }
  }
}


// Row
// =============================================================================

.row {
  @include clearfix();
  @include margin(null (-$gs-gutter / 2));

  &--collapse {
    @include margin(0);

    .col {
      @include padding(0);
    }
  }

  &--reverse {
    .col {
      float: right;
    }
  }
}


// Column
// =============================================================================

.col {
  float: left;
  display: block;
  @include position(relative);
  @include margin(0);
  @include padding(0 ($gs-gutter / 2));

  @include mq($from: desktop) {
    @for $i from 1 through $gs-count {
      &-#{$i} {
        @include size(($i * $gs-column), auto);
      }
    }
  }
}

.md-col {
  @include mq($until: desktop) {
    @for $i from 1 through $gs-count {
      &-#{$i} {
        @include size(($i * $gs-column), auto);
      }
    }
  }
}

.sm-col {
  @include mq($until: tablet) {
    @for $i from 1 through $gs-count {
      &-#{$i} {
        @include size(($i * $gs-column), auto);
      }
    }
  }
}


// Push
// =============================================================================

.push {
  @include mq($from: desktop) {
    @for $i from 1 through $gs-count {
      &-#{$i} {
        $padding: ((((strip-units($gs-container) / $gs-count) * $i) + (strip-units($gs-gutter) / 2)) / strip-units($gs-container)) * 100;
        padding-left: convert-units($padding, '%');
      }
    }
  }
}

.md-push {
  @include mq($until: desktop) {
    @for $i from 1 through $gs-count {
      &-#{$i} {
        $padding: ((((strip-units($gs-container) / $gs-count) * $i) + (strip-units($gs-gutter) / 2)) / strip-units($gs-container)) * 100;
        padding-left: convert-units($padding, '%');
      }
    }
  }
}

.sm-push {
  @include mq($until: tablet) {
    @for $i from 1 through $gs-count {
      &-#{$i} {
        $padding: ((((strip-units($gs-container) / $gs-count) * $i) + (strip-units($gs-gutter) / 2)) / strip-units($gs-container)) * 100;
        padding-left: convert-units($padding, '%');
      }
    }
  }
}

.md-no-push {
  @include mq($until: desktop) {
    padding-left: 0;
  }
}

.sm-no-push {
  @include mq($until: tablet) {
    padding-left: 0;
  }
}
