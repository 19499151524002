@charset 'UTF-8';

/// Mixes a color with white.
///
/// @param {Color} $color
///
/// @param {Number (Percentage)} $percent
///   The amount of white to be mixed in.
///
/// @example scss - Usage
///   .element {
///     background-color: tint(#6ecaa6, 40%);
///   }
///
/// @example css - CSS Output
///   .element {
///     background-color: #a8dfc9;
///   }
///
/// @return {Color}

$tint-color: #fff !default;

@function tint($color, $percent) {
  @return mix($tint-color, $color, $percent);
}
